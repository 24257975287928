import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import Dialog from "../../../components/dialog";

const INITIAL_DATA = {
  packageName: "",
  singlePrice: 0,
  totalPrice: 0,
  status: "ACTIVE",
  quantity: 0,
  packageType: ""
};

const PackageDetailsDialog = ({ onConfirm, packageType, packageDetails, ...props }) => {
  const { t } = useTranslation();
  const { onClose } = props;

  const [packageData, setPackageData] = useState(INITIAL_DATA);

  useEffect(() => {
    setPackageData(INITIAL_DATA);
  }, [onClose, onConfirm]);

  useEffect(() => {
    if (packageDetails) {
      setPackageData({ ...packageDetails, packageType });
    }
  }, [packageDetails]);

  const updateFieldValue = (fieldName, fieldValue) => {
    setPackageData({ ...packageData, [fieldName]: fieldValue });
  };

  const confirmAddPackage = () => {
    setPackageData(INITIAL_DATA);
    if (packageData.packageType === "DISCOUNT_PERCENTAGE") {
      const discountValue = packageData.totalPrice / 100;
      onConfirm({ ...packageData, totalPrice: discountValue, singlePrice: discountValue });
    } else {
      onConfirm(packageData);
    }
  };

  return (
    <Dialog {...props} onConfirm={confirmAddPackage}>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <TextField
            margin="dense"
            label={t("packageDetails.placeholder.packageName")}
            value={packageData.packageName}
            onChange={(e) => updateFieldValue("packageName", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem", margin: 0 }}
          />
        </Grid>
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <InputLabel id="class-status-label" color="neutral">
              {t("packageDetails.placeholder.status")}
            </InputLabel>
            <Select
              labelId="class-status-label"
              id="class-status"
              label={t("packageDetails.placeholder.status")}
              MenuProps={{
                style: { zIndex: 15003 }
              }}
              value={packageData.status}
              onChange={(e) => updateFieldValue("status", e.target.value)}
              sx={{ width: "100%" }}>
              <MenuItem value="ACTIVE">{t("package.status.ACTIVE")}</MenuItem>
              <MenuItem value="INACTIVE">{t("package.status.INACTIVE")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {packageType === "COURSE" || packageType === "ITEM" ? (
          <Grid item>
            <NumericFormat
              margin="dense"
              label={t("packageDetails.placeholder.totalPrice")}
              value={packageData.totalPrice}
              onChange={(e) => {
                const totalPrice = parseInt(e.target.value.replaceAll(",", ""));
                setPackageData({
                  ...packageData,
                  totalPrice,
                  singlePrice:
                    packageType === "COURSE" ? totalPrice / packageData.quantity : totalPrice
                });
              }}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem", margin: 0 }}
              allowLeadingZeros
              thousandSeparator=","
              suffix=" đ"
              customInput={TextField}
            />
          </Grid>
        ) : (
          <Grid item>
            <RadioGroup
              sx={{ paddingX: "0.5rem", margin: 0 }}
              row
              aria-labelledby="status-label"
              name="row-radio-buttons-group"
              value={packageData.packageType}
              onChange={(e) => {
                setPackageData({
                  ...packageData,
                  totalPrice: 0,
                  singlePrice: 0,
                  packageType: e.target.value
                });
              }}>
              <FormControlLabel value="" control={<Radio />} sx={{ display: "none" }} />
              <FormControlLabel
                value="DISCOUNT_AMOUNT"
                control={<Radio />}
                label={t("paymentContract.discountType.AMOUNT.label")}
              />
              <FormControlLabel
                value="DISCOUNT_PERCENTAGE"
                control={<Radio />}
                label={t("paymentContract.discountType.PERCENTAGE.label")}
              />
            </RadioGroup>
            <NumericFormat
              margin="dense"
              label={t("packageDetails.placeholder.discountvalue")}
              value={packageData.totalPrice}
              onChange={(e) => {
                const totalPrice = parseInt(e.target.value.replaceAll(",", ""));
                setPackageData({
                  ...packageData,
                  totalPrice,
                  singlePrice: totalPrice
                });
              }}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem", margin: 0 }}
              allowLeadingZeros
              decimalScale={2}
              thousandSeparator=","
              suffix={packageData.packageType === "DISCOUNT_AMOUNT" ? " đ" : " %"}
              customInput={TextField}
            />
          </Grid>
        )}
        {packageType === "COURSE" && (
          <>
            <Grid item>
              <NumericFormat
                margin="dense"
                label={t("packageDetails.placeholder.courseQuantity")}
                value={packageData.quantity}
                onChange={(e) => {
                  const quantity = parseInt(e.target.value.replaceAll(",", ""));
                  setPackageData({
                    ...packageData,
                    quantity,
                    singlePrice: packageData.totalPrice / quantity
                  });
                }}
                fullWidth
                size="small"
                variant="outlined"
                sx={{ paddingX: "0.5rem", margin: 0 }}
                customInput={TextField}
                allowLeadingZeros
                thousandSeparator=","
              />
            </Grid>
            <Grid item paddingX="0.5rem">
              <Typography variant="h5">
                {t("packageDetails.placeholder.singlePricePreview")}:&nbsp;
                <NumericFormat
                  disabled
                  value={packageData?.singlePrice?.toFixed(2) || 0}
                  allowLeadingZeros
                  thousandSeparator=","
                  suffix=" đ"
                  style={{
                    border: "none",
                    fontSize: "16px",
                    fontWeight: "bold"
                  }}
                />
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  );
};

export default PackageDetailsDialog;
