import { Box, FormControl, Grid, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Header from "../../../components/header";
import Status from "../../../components/status";
import Table from "../../../components/table";
import { get } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import { getStudentDetailLabel } from "../../../utils/UserUtil";

const DEFAULT_SEARCH_CRITERIA = {
  studentUuid: "",
  teacherUuid: "",
  status: "",
  from: moment().subtract(1, "weeks"),
  to: moment()
};

const getWeekDay = (weekDay) => {
  switch (weekDay) {
    case 1:
      return "mon";
    case 2:
      return "tue";
    case 3:
      return "wed";
    case 4:
      return "thu";
    case 5:
      return "fri";
    case 6:
      return "sat";
    case 7:
    default:
      return "sun";
  }
};

const AttendanceHistories = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 30,
    order: "desc",
    orderBy: "scheduleDate"
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [pagination]);

  useEffect(() => {
    setPagination({
      pageNumber: 0,
      pageSize: 30,
      order: "desc",
      orderBy: "scheduleDate"
    });
  }, [searchCriteria, branchInfo.value]);

  const refreshData = () => {
    const { studentValue, teacherValue, classValue, ...criteria } = searchCriteria;
    get("v1/students/attendance/histories", {
      ...pagination,
      ...criteria,
      branchUuid: branchInfo.value.uuid
    })
      .then((res) => {
        const { pageSize, pageNumber } = pagination;
        const histories = res.results.map((x, index) => ({
          id: pageNumber * pageSize + index + 1,
          ...x
        }));
        setTotal(res.total);
        setData(histories);
      })
      .catch(console.debug);
  };

  const handleCriteriaChange = (field, value) => {
    setSearchCriteria({ ...searchCriteria, [field]: value || undefined });
  };

  const columns = [
    { field: "id", label: "attendanceHistory.table.field.id.label" },
    {
      field: "studentName",
      label: "attendanceHistory.table.field.studentName.label"
    },
    {
      field: "teacherName",
      label: "attendanceHistory.table.field.teacherName.label"
    },
    {
      field: "className",
      label: "attendanceHistory.table.field.className.label"
    },
    {
      field: "classroomName",
      label: "attendanceHistory.table.field.classroomName.label"
    },
    {
      field: "scheduleDate",
      label: "attendanceHistory.table.field.scheduleDate.label",
      valueGetter: ({ scheduleDate, weekDay }) =>
        `${t(`timetable.weekDay.${getWeekDay(weekDay)}.label`)} - ${moment(scheduleDate).format(
          "DD/MM/YYYY"
        )}`
    },
    {
      field: "timeSlot",
      label: "attendanceHistory.table.field.timeSlot.label"
    },
    {
      field: "status",
      label: "attendanceHistory.table.field.status.label",
      align: "center",
      component: (rowData) => (
        <Status
          status={`attendance.historyStatus.${rowData.status}`}
          color={getStatusColor(rowData.status)}
        />
      )
    }
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "secondary";
      case "MAKE_UP_UNCOMPLETED":
        return "warning";
      case "CHECKED_IN":
        return "success";
      case "ABSENCE":
        return "error";
      case "MAKE_UP_COMPLETED":
        return "info";
      case "LEAVE_OF_ABSENCE":
      case "ABORTED":
      default:
        return "primary";
    }
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Stars World Online Management" />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="attendanceHistory.placeholder.student"
                value={
                  searchCriteria.studentValue || {
                    uuid: "",
                    displayName: getStudentDetailLabel(null)
                  }
                }
                onChange={(e, value) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    studentUuid: value.uuid,
                    studentValue: value
                  });
                }}
                requestConfig={{
                  url: `v1/students?branchUuid=${branchInfo.value.uuid}`,
                  responseField: "results",
                  label: (student) => getStudentDetailLabel(student),
                  value: "uuid",
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="attendanceHistory.placeholder.teacher"
                value={
                  searchCriteria.teacherValue || {
                    uuid: "",
                    niceName: ""
                  }
                }
                onChange={(e, value) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    teacherUuid: value.uuid,
                    teacherValue: value
                  });
                }}
                requestConfig={{
                  url: `v1/teachers?status=ACTIVE&branchUuid=${branchInfo.value.uuid}`,
                  responseField: "results",
                  label: "niceName",
                  value: "uuid",
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="studentlist-status-label" color="neutral">
                {t("attendanceHistory.placeholder.status")}
              </InputLabel>
              <Select
                labelId="student-status-label"
                id="student-status"
                label={t("attendanceHistory.placeholder.status.label")}
                value={searchCriteria.status || ""}
                onChange={(e) => handleCriteriaChange("status", e.target.value)}>
                <MenuItem value="">{t("attendance.historyStatus.ALL")}</MenuItem>
                <MenuItem value="ACTIVE">{t("attendance.historyStatus.ACTIVE")}</MenuItem>
                <MenuItem value="LEARNING">{t("attendance.historyStatus.LEARNING")}</MenuItem>
                <MenuItem value="CHECKED_IN">{t("attendance.historyStatus.CHECKED_IN")}</MenuItem>
                <MenuItem value="ABSENCE">{t("attendance.historyStatus.ABSENCE")}</MenuItem>
                <MenuItem value="LEAVE_OF_ABSENCE">
                  {t("attendance.historyStatus.LEAVE_OF_ABSENCE")}
                </MenuItem>
                <MenuItem value="MAKE_UP_UNCOMPLETED">
                  {t("attendance.historyStatus.MAKE_UP_UNCOMPLETED")}
                </MenuItem>
                <MenuItem value="MAKE_UP_COMPLETED">
                  {t("attendance.historyStatus.MAKE_UP_COMPLETED")}
                </MenuItem>
                <MenuItem value="ABORTED">{t("attendance.historyStatus.ABORTED")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          {" "}
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="attendanceHistory.placeholder.class"
                value={
                  searchCriteria.classValue || {
                    uuid: "",
                    className: ""
                  }
                }
                onChange={(e, value) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    classUuid: value?.uuid,
                    classValue: value
                  });
                }}
                requestConfig={{
                  url: `v1/classes?branchUuid=${branchInfo.value.uuid}`,
                  label: "className",
                  value: "uuid",
                  responseField: (res) => res.results.map(({ classDetails }) => classDetails),
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="start-date"
                format="DD/MM/YYYY"
                label={t("attendanceHistory.placeholder.from")}
                value={searchCriteria.from}
                onChange={(e) => setSearchCriteria({ ...searchCriteria, from: e })}
                sx={{ width: "100%", paddingX: "0.5rem" }}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="start-date"
                format="DD/MM/YYYY"
                label={t("attendanceHistory.placeholder.to")}
                value={searchCriteria.to}
                onChange={(e) => setSearchCriteria({ ...searchCriteria, to: e })}
                sx={{ width: "100%", paddingX: "0.5rem" }}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table
          columnConfig={columns}
          data={data}
          totalResults={total}
          serverPaging={true}
          defaultPagination={pagination}
          paginationChanged={(paging) => setPagination(paging)}
        />
      </Box>
    </Box>
  );
};

export default AttendanceHistories;
