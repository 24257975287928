import moment from "moment";
import CookieConstants from "../constants/Cookies";
import { get } from "../services/HttpClient";
import { branches, branchInfo, userProfile } from "../signals";
import { getCookie, setCookie } from "./CookieUtil";

export const getDisplayName = (user) => {
  if (!user) {
    return "";
  }

  if (user.niceName) {
    return user.niceName;
  }

  const { firstName, lastName, displayName } = user;
  const fullName = ((firstName || "") + " " + (lastName || "")).trim();
  if (fullName && displayName) {
    return fullName + " - " + displayName;
  }
  return fullName || displayName;
};

export const getStudentDetailLabel = (student) => {
  if (!student) {
    return "";
  }

  const studentName = getDisplayName(student);
  const studentBirthday = student.birthday
    ? ` (${moment(student.birthday).format("DD/MM/YYYY")})`
    : "";

  let parentName = "";
  if (student.parent) {
    parentName = getDisplayName(student.parent);
    if (student.parent.mobile && student.parent.mobile !== "-") {
      parentName = parentName + " - 0" + student.parent.mobile;
    }
  }

  return `${studentName}${studentBirthday}${parentName ? " (" + parentName + ")" : ""}`;
};

export const fetchCurrentInfo = (successCallback, errorHandler) =>
  get("v1/user/profile")
    .then((res) => {
      userProfile.value = res;
      return res;
    })
    .then((res) => {
      const { roles, branchUuid } = res;
      // Admin can access all branches
      if (roles.includes("ADMINISTRATOR")) {
        return get("v1/admin/branches");
      }
      return get(`v1/admin/branches/${branchUuid}`);
    })
    .then((branchRes) => {
      const currentBranchUuid = getCookie(CookieConstants.CURRENT_BRANCH_UUID);
      if (Array.isArray(branchRes)) {
        branches.value = branchRes;
        branchInfo.value = branchRes.find(({ uuid }) => uuid === currentBranchUuid) || branchRes[0];
      } else {
        branches.value = [];
        branchInfo.value = branchRes;
      }
      setCookie(CookieConstants.CURRENT_BRANCH_UUID, branchInfo.value.uuid);
      successCallback();
    })
    .catch((err) => {
      errorHandler(err);
    });
