import { Autocomplete as MUIAutocomplete, Popper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BASE_URL, httpClient } from "../../services/HttpClient";

const CustomPopper = (props) => {
  return (
    <Popper
      {...props}
      placement="bottom"
      style={{ width: props.anchorEl.clientWidth, zIndex: 15003, height: "5px" }}
    />
  );
};

const Autocomplete = ({ requestConfig, placeholder, ...props }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [queryValue, setQueryValue] = useState("");
  const loading = open && options.length === 0;
  const { t } = useTranslation();

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    const { url, paramsObj, responseField } = requestConfig;
    httpClient
      .get(`${BASE_URL}${url}${queryValue}`, paramsObj || {})
      .then((res) => {
        if (active) {
          if (Array.isArray(res)) {
            setOptions(res);
          } else if (Object.prototype.toString.call(responseField) === "[object Function]") {
            setOptions(responseField(res));
          } else {
            setOptions(res[responseField]);
          }
        }
      })
      .catch(console.debug);

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (queryValue) {
      const timeOutId = setTimeout(() => {
        setOpen(true);
        setOptions([]);
      }, 200);
      return () => clearTimeout(timeOutId);
    }
  }, [queryValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const getLabelValue = (obj, fieldOrCallback) => {
    if (Object.prototype.toString.call(fieldOrCallback) === "[object Function]") {
      return fieldOrCallback(obj);
    }
    return obj[fieldOrCallback] || "";
  };

  return (
    <MUIAutocomplete
      {...props}
      id="asynchronous"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => {
        if (!requestConfig.value) {
          return !value || option === value;
        }
        return (
          !value[requestConfig.value] || option[requestConfig.value] === value[requestConfig.value]
        );
      }}
      getOptionLabel={(option) => getLabelValue(option, requestConfig.label)}
      options={options}
      onInputChange={(e) => {
        if (Object.prototype.toString.call(requestConfig.onQueryChange) === "[object Function]") {
          setQueryValue(requestConfig.onQueryChange(e?.target?.value));
        }
      }}
      loading={loading}
      size="small"
      fullWidth={true}
      PopperComponent={CustomPopper}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(placeholder)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};
export default Autocomplete;
