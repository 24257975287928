import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  ButtonGroup,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { createRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import { Button } from "../../../components/button";
import Header from "../../../components/header";
import { WEEK_DAYS } from "../../../constants/Timetable";
import { get } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import StyleWrapper from "./StyleWrapper";

const getTime = (weekYear, dayOfWeek, timeSlot) => {
  const weekYearParts = weekYear.split("|");
  const timeParts = timeSlot.split(":");
  let day = moment().isoWeekday(dayOfWeek);
  day.isoWeek(weekYearParts[0]);
  day.isoWeekYear(weekYearParts[1]);
  day.hour(timeParts[0]);
  day.minutes(timeParts[1]);
  return day.toDate();
};

const getWeekOptions = (pastWeeks = 12, futureWeeks = 72) => {
  const now = moment();
  const weekOptions = [];
  for (let i = 0; i < pastWeeks; i++) {
    const date = now.clone().subtract(pastWeeks - i, "weeks");
    const startDayOfWeek = date.clone().isoWeekday(1);
    const endDayOfWeek = date.clone().isoWeekday(7);
    weekOptions.push({
      week: date.isoWeek(),
      year: date.isoWeekYear(),
      from: startDayOfWeek,
      to: endDayOfWeek
    });
  }
  for (let i = 0; i < futureWeeks; i++) {
    const date = now.clone().add(i, "weeks");
    const startDayOfWeek = date.clone().isoWeekday(1);
    const endDayOfWeek = date.clone().isoWeekday(7);
    weekOptions.push({
      week: date.isoWeek(),
      year: date.isoWeekYear(),
      from: startDayOfWeek,
      to: endDayOfWeek
    });
  }
  return weekOptions;
};

const DEFAULT_SEARCH_CRITERIA = {
  weekYear: moment().isoWeek() + "|" + moment().isoWeekYear(),
  from: moment().isoWeekday(1),
  to: moment().isoWeekday(7),
  weekDay: ""
};

const Timetable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const calendarRef = createRef();
  const { t } = useTranslation();
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);
  const [scheduleData, setScheduleData] = useState([]);

  const handleCriteriaChange = (field, value) => {
    setSearchCriteria({ ...searchCriteria, [field]: value || "" });
  };

  useEffect(() => {
    if (branchInfo.value) {
      get("v1/admin/schedule", { ...searchCriteria, branchUuid: branchInfo.value.uuid })
        .then((res) => {
          const { weekYear } = searchCriteria;
          setScheduleData(
            res.map(
              ({
                className,
                classroomName,
                timeSlot,
                dayOfWeek,
                teacherName,
                learningQuantity
              }) => {
                const timeSlotParts = timeSlot.split("-");
                return {
                  title: className,
                  start: getTime(weekYear, dayOfWeek, timeSlotParts[0]),
                  end: getTime(weekYear, dayOfWeek, timeSlotParts[1]),
                  extendedProps: {
                    className,
                    room: classroomName,
                    numberOfStudents: learningQuantity,
                    timeSlot,
                    teacherName
                  }
                };
              }
            )
          );
        })
        .catch(console.debug);
    }
  }, [searchCriteria, branchInfo.value]);

  const renderDayHeaderContent = ({ text, date }) => {
    const weekDay = text.substring(0, 3).toLowerCase();
    const day = date.toLocaleDateString("en-GB");
    return (
      <b>
        {t(`timetable.weekDay.${weekDay}.label`)}
        <br />
        {day}
      </b>
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const renderEventContent = ({ event }) => {
    return (
      <>
        <Typography color={colors.grey[900]} aria-describedby="test" variant="contained">
          <span>{t("timetable.className.label")}: </span>
          <b>{event.extendedProps.className}</b>
          <br />
          <span>{t("timetable.teacherName.label")}: </span>
          <b>{event.extendedProps.teacherName}</b>
          <br />
          <b>{event.extendedProps.room}</b>&nbsp;
          <i>({event.extendedProps.timeSlot})</i>
        </Typography>
        <Popover
          id="test"
          // open={!!anchorEl}
          open={false}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          sx={{
            zIndex: 20000
          }}>
          <Typography sx={{ p: 2 }}>
            {t("timetable.studentQuantity.label")}:&nbsp;
            <b>{event.extendedProps.numberOfStudents || 0}</b>
          </Typography>
        </Popover>
      </>
    );
  };

  const handleWeekChange = (weekYear, changeView = false) => {
    const { from, to } = getWeekOptions().find(({ week, year }) => {
      return week + "|" + year === weekYear;
    });
    if (changeView) {
      calendarRef.current.getApi().changeView("timeGridWeek", from.toDate());
    }
    setSearchCriteria({
      ...searchCriteria,
      from: from,
      to: to,
      weekYear
    });
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Stars World Online Management" />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="class-status-label" color="neutral">
                {t("timetable.placeholder.weekOptions")}
              </InputLabel>
              <Select
                labelId="class-status-label"
                id="class-status"
                label={t("timetable.placeholder.weekOptions")}
                value={searchCriteria.weekYear}
                onChange={(e) => handleWeekChange(e.target.value, true)}
                MenuProps={{
                  style: { zIndex: 15002 }
                }}
                sx={{ width: "100%" }}>
                {getWeekOptions().map(({ week, year, from, to }, index) => (
                  <MenuItem key={index} value={week + "|" + year}>
                    {t("timetable.weekOptions.label", {
                      week,
                      year,
                      startDayOfWeek: from.format("DD/MM"),
                      endDayOfWeek: to.format("DD/MM")
                    })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="class-status-label" color="neutral">
                {t("timetable.placeholder.weekDayOptions")}
              </InputLabel>
              <Select
                labelId="class-status-label"
                id="class-status"
                label={t("timetable.placeholder.weekDayOptions")}
                value={searchCriteria.weekDay}
                onChange={(e) => handleCriteriaChange("weekDay", e.target.value)}
                MenuProps={{
                  style: { zIndex: 15002 }
                }}
                sx={{ width: "100%" }}>
                {WEEK_DAYS.map(({ label, value }, index) => (
                  <MenuItem key={index} value={value}>
                    {t(label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="timetable.placeholder.teacherOptions"
                onChange={(e, value) => {
                  handleCriteriaChange("teacherUuid", value?.uuid || undefined);
                }}
                requestConfig={{
                  url: `v1/teachers?status=ACTIVE&branchUuid=${branchInfo.value.uuid}&orderBy=displayName&order=asc`,
                  label: "displayName",
                  value: "uuid",
                  responseField: "results"
                }}></Autocomplete>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="timetable.placeholder.courseOptions"
                onChange={(e, value) => {
                  handleCriteriaChange("courseUuid", value?.uuid || undefined);
                }}
                requestConfig={{
                  url: `v1/admin/courses?branchUuid=${branchInfo.value.uuid}`,
                  label: "courseName",
                  value: "uuid"
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="timetable.placeholder.classOptions"
                onChange={(e, value) => {
                  handleCriteriaChange("classUuid", value?.uuid || undefined);
                }}
                requestConfig={{
                  url: `v1/classes?branchUuid=${branchInfo.value.uuid}`,
                  label: "className",
                  value: "uuid",
                  responseField: (res) => res.results.map(({ classDetails }) => classDetails),
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              disabled
              label={t("timetable.placeholder.session")}
              variant="outlined"
              sx={{ width: "100%", paddingX: "0.5rem" }}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label={t("timetable.placeholder.fromTime")}
                ampm={false}
                onChange={(e) => {
                  let hour = e.hour();
                  let min = e.minutes();
                  if (hour.length < 2) {
                    hour = "0" + hour;
                  }
                  if (min.length < 2) {
                    min = "0" + min;
                  }
                  handleCriteriaChange("startTime", hour + ":" + min);
                }}
                sx={{ width: "100%", padding: "0 0.5rem" }}
                timeSteps={{ hours: 1, minutes: 15 }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label={t("timetable.placeholder.toTime")}
                ampm={false}
                onChange={(e) => {
                  let hour = e.hour();
                  let min = e.minutes();
                  if (hour.length < 2) {
                    hour = "0" + hour;
                  }
                  if (min.length < 2) {
                    min = "0" + min;
                  }
                  handleCriteriaChange("endTime", hour + ":" + min);
                }}
                sx={{ width: "100%", padding: "0 0.5rem" }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="timetable.placeholder.roomOptions"
                onChange={(e, value) => {
                  handleCriteriaChange("classroomUuid", value?.uuid || undefined);
                }}
                requestConfig={{
                  url: `v1/admin/classrooms?branchUuid=${branchInfo.value.uuid}&status=ACTIVE`,
                  label: "classroomName",
                  value: "uuid"
                }}></Autocomplete>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row-reverse" columnSpacing={1}>
          <Grid item xs={4} sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              sx={{ marginX: "0.5rem" }}>
              <Button startIcon={<DownloadIcon />} variant="contained" color="neutral" disabled>
                {t("common.button.excel")}
              </Button>
              <Button
                variant="contained"
                color="success"
                startIcon={<RestartAltIcon />}
                onClick={() => setSearchCriteria(DEFAULT_SEARCH_CRITERIA)}>
                {t("common.button.reset")}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
      <Container
        sx={{
          backgroundColor: colors.primary[400],
          marginBottom: "0.5rem",
          padding: "0.5rem",
          maxWidth: "100% !important"
        }}>
        <StyleWrapper>
          <FullCalendar
            ref={calendarRef}
            // initial view
            initialView="timeGridWeek"
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, momentPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "timeGridWeek,timeGridDay"
            }}
            datesSet={({ start }) => {
              const targetDate = moment(start);
              const weekYear = targetDate.isoWeek() + "|" + targetDate.isoWeekYear();
              handleWeekChange(weekYear);
            }}
            titleFormat="DD/MM/YYYY"
            titleRangeSeparator={" \u2013 "}
            buttonText={{
              today: t("timetable.today.label"),
              month: t("timetable.month.label"),
              week: t("timetable.week.label"),
              day: t("timetable.day.label")
            }}
            height="60vh"
            handleWindowResize
            stickyFooterScrollbar
            stickyHeaderDates
            firstDay={1}
            // calendar formats
            dayPopoverFormat="DD/MM/YYYY"
            slotLabelFormat={{
              hour: "2-digit",
              minute: "2-digit",
              meridiem: "long"
            }}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              hour12: false
            }}
            // slot configs
            slotMinTime="08:00:00"
            slotMaxTime="21:00:00"
            displayEventTime={true}
            selectable={true}
            slotDuration="01:00:00"
            allDaySlot={false}
            // custom content
            dayHeaderContent={renderDayHeaderContent}
            eventContent={renderEventContent}
            // event configs
            eventMaxStack={1}
            eventMinHeight={50}
            eventClick={({ jsEvent }) => {
              setAnchorEl(jsEvent.target);
            }}
            // schedule
            events={scheduleData}
          />
        </StyleWrapper>
      </Container>
    </Box>
  );
};

export default Timetable;
