import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  InputLabel,
  Table as MUITable,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme";

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

const Table = (props) => {
  const {
    columnConfig,
    data,
    maxHeight,
    rowActions,
    checkboxSelection,
    selectionsChanged,
    sortsChanged,
    paginationChanged,
    rowColumnSelectedChanged,
    checkBoxSelectLabel,
    confirmSelections,
    defaultPagination,
    totalResults,
    serverPaging = false,
    showHeaderToolbar = true,
    showFooterToolbar = true
  } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const {
    pageNumber: defaultPageNumber,
    pageSize: defaultPageSize,
    order: defaultOrder,
    orderBy: defaultOrderBy
  } = defaultPagination || {};

  const rowsPerPageOptions = props.rowsPerPageOptions || [
    30, 50, 100
    // { label: t("table.rowsPerPage.all.label"), value: -1 }
  ];
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize || rowsPerPageOptions[0]);
  const [page, setPage] = useState(defaultPageNumber || 0);
  const [order, setOrder] = useState(defaultOrder || "asc");
  const [orderBy, setOrderBy] = useState(defaultOrderBy || "");
  const [totalSelections, setTotalSelections] = useState(0);
  const [selected, setSelected] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(columnConfig);
  const [checkedRowColIndexes, setCheckedRowColIndexes] = useState([]);

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - ((serverPaging ? totalResults : data?.length) || 0))
      : 0;
  const numberOfColumns =
    (columnConfig?.length || 0) + (rowActions ? 1 : 0) + (checkboxSelection ? 1 : 0);

  const handlePropsChanged = (props, ...args) => {
    if (Object.prototype.toString.call(props) === "[object Function]") {
      props(...args);
    }
  };

  const handleChangePage = (event, newPage) => {
    handlePropsChanged(paginationChanged, {
      pageNumber: newPage,
      pageSize: rowsPerPage,
      order,
      orderBy
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    handlePropsChanged(paginationChanged, {
      pageNumber: 0,
      pageSize: newRowsPerPage,
      order,
      orderBy
    });
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const descendingComparator = (a, b, field) => {
    if (b[field] < a[field]) {
      return -1;
    }
    if (b[field] > a[field]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  const stableSort = (rows, comparator) => {
    const stabilizedThis = rows.map((rowData, index) => [rowData, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleSortRequest = (sortField) => (event) => {
    const isAsc = orderBy === sortField && order === "asc";
    if (sortsChanged) {
      handlePropsChanged(sortsChanged, sortField, isAsc);
    }
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(sortField);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = [...data].filter((x) => !x.hideCheckbox);
      setSelected(newSelected);
      setTotalSelections(newSelected.length);
      handlePropsChanged(selectionsChanged, newSelected);
      return;
    }
    setSelected([]);
    handlePropsChanged(selectionsChanged, 0);
    setTotalSelections(0);
  };

  const handleRowSelect = (rowData) => {
    const selectedIndex = selected.indexOf(rowData);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowData);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    handlePropsChanged(selectionsChanged, newSelected);
    setTotalSelections(newSelected.length);
  };

  const isSelected = (rowData) => selected.indexOf(rowData) !== -1;

  const visibleRows = useMemo(() => {
    setSelected([]);
    handlePropsChanged(selectionsChanged, 0);
    setTotalSelections(0);
    return serverPaging || !showFooterToolbar
      ? data
      : stableSort(data, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
  }, [data, order, orderBy, page, rowsPerPage]);

  const getRowValue = (column, rowData) => {
    const { valueGetter, field } = column;
    if (valueGetter) {
      if (Object.prototype.toString.call(valueGetter) === "[object Function]") {
        return valueGetter(rowData);
      }
      return valueGetter;
    }
    return rowData[field];
  };

  const handleColumnsChange = (event) => {
    const selectedFields = event.target.value.map(({ field }) => field);
    const selectedColumns = columnConfig.filter(({ field }) => selectedFields.includes(field));
    setVisibleColumns(selectedColumns);
  };

  const resetTable = () => {
    setVisibleColumns(columnConfig);
    setRowsPerPage(rowsPerPageOptions[0]);
    setPage(0);
    setOrder("asc");
    setOrderBy("");
    if (checkboxSelection) {
      setTotalSelections(0);
      setSelected([]);
    }
  };

  const handleDisplayedRows = ({ from, to, count }) => {
    return t("table.displayedRows.label", {
      from,
      to,
      count
    });
  };

  const renderHeaderColumnCheckbox = (colIndex, checkboxId) => {
    const rowsCheckedByCurrentColumn = checkedRowColIndexes.filter((x) => x.colIndex === colIndex);
    const rowsCheckedByOthers = checkedRowColIndexes.filter((x) => x.colIndex !== colIndex);
    return (
      <Checkbox
        color="neutral"
        indeterminate={
          rowsCheckedByCurrentColumn.length > 0 && rowsCheckedByCurrentColumn.length < data.length
        }
        checked={rowsCheckedByCurrentColumn.length === data.length}
        onChange={(e) => {
          let rowColChecked = [];
          if (
            !e.target.checked ||
            (rowsCheckedByCurrentColumn.length > 0 &&
              rowsCheckedByCurrentColumn.length < data.length)
          ) {
            rowColChecked = [...rowsCheckedByOthers];
          } else {
            const result = [];
            data.forEach((_, rowIndex) => {
              if (
                rowsCheckedByOthers.findIndex(
                  (x) => x.rowIndex === rowIndex && x.checkboxId === checkboxId
                ) === -1
              ) {
                result.push({
                  checkboxId,
                  rowIndex,
                  colIndex
                });
              }
            });
            rowColChecked = [...rowsCheckedByOthers, ...result];
          }
          setCheckedRowColIndexes(rowColChecked);
          handlePropsChanged(rowColumnSelectedChanged, rowColChecked);
        }}
      />
    );
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: maxHeight || "70vh" }}>
      {showHeaderToolbar && (
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          p="1rem">
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<RestartAltIcon />}
              color="secondary"
              onClick={resetTable}
              sx={{ textTransform: "none" }}>
              {t("table.button.reset.label")}
            </Button>
          </Grid>
          <Grid item xs={10}>
            <InputLabel id="selected-columns-chip">
              {t("table.selectedColumns.label", { count: visibleColumns.length })}
            </InputLabel>
            <Select
              labelId="selected-columns-label"
              id="selected-columns"
              multiple
              size="small"
              value={visibleColumns}
              onChange={handleColumnsChange}
              input={<OutlinedInput id="selected-columns-chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map(({ label }, index) => {
                    if (index < 10) {
                      return (
                        <Chip key={index} size="small" label={index === 9 ? "..." : t(label)} />
                      );
                    }
                    return null;
                  })}
                </Box>
              )}
              MenuProps={{
                style: { zIndex: 15002 }
              }}>
              {columnConfig.map((column, index) => (
                <MenuItem key={index} value={column} dense>
                  {t(column.label)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      )}
      {checkboxSelection && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          px="1rem"
          pb="1rem">
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Typography fontWeight="600" sx={{ paddingRight: "1rem", textAlign: "center" }}>
              {t("table.selectedItems.label", { count: selected.length })}
            </Typography>
            <Button
              disabled={selected.length === 0}
              variant="contained"
              startIcon={<LibraryAddCheckIcon />}
              color="secondary"
              onClick={() => handlePropsChanged(confirmSelections, selected)}
              sx={{ textTransform: "none" }}>
              {t(checkBoxSelectLabel || "table.button.selected.label")}
            </Button>
          </Grid>
        </Grid>
      )}
      <MUITable stickyHeader aria-label="table" size="small">
        <TableHead>
          <TableRow>
            {checkboxSelection && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="neutral"
                  disabled={!data || data.length === 0 || data.every((x) => x.hideCheckbox)}
                  indeterminate={totalSelections > 0 && totalSelections < data.length}
                  checked={data.length > 0 && totalSelections === data.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all"
                  }}
                />
              </TableCell>
            )}
            {visibleColumns.map((column, index) => (
              <TableCell
                key={index}
                width={column.width}
                align={column.align || "left"}
                sortDirection={orderBy === column.field ? order : false}
                sx={{ fontWeight: "bold", color: column.textColor }}>
                {column.checkbox && renderHeaderColumnCheckbox(index, column.checkboxId || index)}
                {column.sortable === false ? (
                  t(column.label)
                ) : (
                  <TableSortLabel
                    active={orderBy === column.field}
                    align={column.align || "left"}
                    direction={orderBy === column.field ? order : "asc"}
                    onClick={handleSortRequest(column.field)}>
                    {t(column.label)}
                    {orderBy === column.field ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
            {rowActions?.length > 0 && (
              <TableCell key="actions" align="center" sx={{ fontWeight: "bold" }}>
                {t("table.actions.label")}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!data || data.length === 0 ? (
            <TableRow>
              <TableCell colSpan={numberOfColumns}>
                <Typography
                  variant="h3"
                  fontWeight="600"
                  sx={{ padding: "1rem", textAlign: "center", color: colors.grey[700] }}>
                  {t("table.noDataToDisplay.label")}
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            <Fragment>
              {visibleRows.map((rowData, rowIndex) => {
                const isItemSelected = isSelected(rowData);
                const labelId = `enhanced-table-checkbox-${rowIndex}`;
                return (
                  <TableRow
                    key={rowIndex}
                    hover
                    onClick={() => checkboxSelection && handleRowSelect(rowData)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    sx={{ cursor: checkboxSelection ? "pointer" : "unset" }}>
                    {checkboxSelection && (
                      <TableCell padding="checkbox" size="small">
                        {!rowData.hideCheckbox && (
                          <Checkbox
                            color="neutral"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId
                            }}
                          />
                        )}
                      </TableCell>
                    )}
                    {visibleColumns.map(({ component: Component, ...column }, colIndex) => (
                      <TableCell
                        key={colIndex}
                        align={column.align}
                        width={column.width}
                        style={{ minWidth: column.minWidth, color: column.textColor }}
                        size="small">
                        {column.checkbox && (
                          <Checkbox
                            color="neutral"
                            disabled={
                              checkedRowColIndexes.findIndex(
                                (x) =>
                                  x.rowIndex === rowIndex &&
                                  x.checkboxId === column.checkboxId &&
                                  x.colIndex !== colIndex
                              ) > -1
                            }
                            checked={
                              checkedRowColIndexes.findIndex(
                                (x) => x.rowIndex === rowIndex && x.colIndex === colIndex
                              ) > -1
                            }
                            onChange={(e) => {
                              const rowColIndex = checkedRowColIndexes.findIndex(
                                (x) => x.rowIndex === rowIndex && x.colIndex === colIndex
                              );
                              if (e.target.checked && rowColIndex === -1) {
                                checkedRowColIndexes.push({
                                  checkboxId: column.checkboxId || colIndex,
                                  rowIndex,
                                  colIndex
                                });
                              } else if (!e.target.checked && rowColIndex > -1) {
                                checkedRowColIndexes.splice(rowColIndex, 1);
                              }
                              setCheckedRowColIndexes([...checkedRowColIndexes]);
                              handlePropsChanged(rowColumnSelectedChanged, checkedRowColIndexes);
                            }}
                            inputProps={{
                              "aria-labelledby": labelId
                            }}
                          />
                        )}
                        {Component ? <Component {...rowData} /> : getRowValue(column, rowData)}
                      </TableCell>
                    ))}
                    {rowActions && (
                      <TableCell key="actions" align="center" size="small">
                        {rowActions
                          .filter(({ showIf }) => !showIf || showIf(rowData))
                          .map(({ tooltip, icon, action }, index) => (
                            <Tooltip key={index} title={tooltip} placement="top">
                              <IconButton
                                aria-label={icon}
                                onClick={() => handlePropsChanged(action, rowData)}>
                                {icon}
                              </IconButton>
                            </Tooltip>
                          ))}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={numberOfColumns} size="small" />
                </TableRow>
              )}
            </Fragment>
          )}
        </TableBody>
        {showFooterToolbar && (
          <TableFooter
            sx={{ position: "sticky", bottom: "-1px", backgroundColor: colors.grey[900] }}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                colSpan={numberOfColumns}
                count={totalResults || data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={handleDisplayedRows}
                labelRowsPerPage={t("table.rowsPerPage.label")}
              />
            </TableRow>
          </TableFooter>
        )}
      </MUITable>
    </TableContainer>
  );
};

export default Table;
