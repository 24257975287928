import { v4 as uuidv4 } from "uuid";
import CookieConstants from "../../constants/Cookies";
import RequestHeaders from "../../constants/RequestHeaders";
import { currentRequests, globalError, globalLoading } from "../../signals";
import { getCookie } from "../../utils/CookieUtil";

const ERROR_IGNORED_PATHS = ["v1/user/profile", "v1/auth"];

export const requestHandler = (req) => {
  const requestId = uuidv4();
  let customError = false;
  if (
    req.headers.has(RequestHeaders.CUSTOM_ERROR_HANDLER) &&
    req.headers.has(RequestHeaders.CUSTOM_ERROR_HANDLER) === "true"
  ) {
    customError = true;
  }
  // add unique requestId to verify in repsonse
  req.requestId = requestId;
  currentRequests.value.push({
    requestId,
    customError
  });

  const accessToken = getCookie(CookieConstants.ACCESS_TOKEN);
  req.headers["Authorization"] = `Bearer ${accessToken}`;
  return req;
};

export const responseHandler = (res) => {
  const requestStacks = [...currentRequests.value];
  const { requestId } = res.config;
  const requestIndexToEject = currentRequests.value.findIndex((x) => x.requestId === requestId);
  if (requestIndexToEject !== -1) {
    requestStacks.splice(requestIndexToEject, 1);
    currentRequests.value = requestStacks;
  }
  globalLoading.value = false;
  return res.data;
};

export const errorHandler = (err) => {
  const { requestId, url } = err.response?.config || {};
  let showError = !ERROR_IGNORED_PATHS.some((path) => url.includes(path));
  const requestStacks = [...currentRequests.value];
  const requestIndexToEject = currentRequests.value.findIndex((x) => x.requestId === requestId);
  if (requestIndexToEject !== -1) {
    const requestToEject = requestStacks.splice(requestIndexToEject, 1)[0];
    console.debug(JSON.stringify(requestToEject));
    if (requestToEject.customError) {
      showError = false;
    }
    currentRequests.value = requestStacks;
  }
  if (showError) {
    const errorResponse = err.response?.data || {};
    globalError.value = {
      message: errorResponse.message || errorResponse.error || "commonMsg.error.genericMessage",
      detailsMsg: errorResponse.reason || ""
    };
  }
  globalLoading.value = false;
  return Promise.reject(err);
};
