import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";
import { branchInfo } from "../../../signals";
import { getStudentDetailLabel } from "../../../utils/UserUtil";

const INITIAL_DATA = {
  studentUuid: "",
  studentName: "",
  startDate: moment(),
  sessions: "",
  applySchedule: "",
  note: "",
  status: "STUDENT_LEARNING",
  classScheduleUuids: []
};

const getWeekDay = (dayOfWeek) => (dayOfWeek ? dayOfWeek.substring(0, 3).toLowerCase() : "");

const AddStudentDialog = ({ onConfirm, onClose, schedule, ...props }) => {
  const { t } = useTranslation();

  const [addStudentDetails, setAddStudentDetails] = useState(INITIAL_DATA);

  useEffect(() => {
    setAddStudentDetails({ ...INITIAL_DATA, classScheduleUuids: schedule.map(({ uuid }) => uuid) });
  }, [schedule]);

  const updateFieldValue = (fieldName, fieldValue) => {
    setAddStudentDetails({ ...addStudentDetails, [fieldName]: fieldValue });
  };

  const confirmAddStudent = () => {
    onConfirm(addStudentDetails);
  };

  const cancelAddStudent = () => {
    setAddStudentDetails({ ...INITIAL_DATA, classScheduleUuids: schedule.map(({ uuid }) => uuid) });
    onClose();
  };

  return (
    <Dialog {...props} onConfirm={confirmAddStudent} onClose={cancelAddStudent}>
      <Grid
        container
        p="1rem"
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <Autocomplete
              onChange={(e, value) => {
                updateFieldValue("studentUuid", value?.uuid || "");
              }}
              requestConfig={{
                url: `v1/students?branchUuid=${branchInfo.value.uuid}`,
                responseField: "results",
                label: (student) => getStudentDetailLabel(student),
                onQueryChange: (value) => (value ? `&any=${value}` : "")
              }}></Autocomplete>
          </FormControl>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="start-date"
              label={t("addStudent.placeholder.startDate")}
              format="DD/MM/YYYY"
              sx={{ width: "100%", paddingX: "0.5rem" }}
              value={addStudentDetails.startDate}
              onChange={(e) => updateFieldValue("startDate", e)}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <FormControlLabel
            sx={{ paddingX: "0.5rem", marginRight: 0 }}
            value="STUDENT_TRIAL"
            control={<Checkbox />}
            onChange={(e) => {
              setAddStudentDetails({
                ...addStudentDetails,
                status: e.target.checked ? "STUDENT_TRIAL" : "STUDENT_LEARNING",
                sessions: e.target.checked ? 2 : addStudentDetails.sessions
              });
            }}
            label={t("addStudent.placeholder.trialStatus")}
          />
          <Typography
            component="div"
            sx={{
              marginX: "0.5rem",
              padding: "0.5rem",
              borderRadius: "5px",
              backgroundColor: "#c9c5c5"
            }}>
            <div
              dangerouslySetInnerHTML={{
                __html: t("addStudent.description.sessions", {
                  scheduleDate: addStudentDetails?.startDate.format("DD/MM/YYYY")
                })
              }}
            />
            {(schedule || [])
              .filter(({ uuid }) => addStudentDetails?.classScheduleUuids?.includes(uuid))
              .map(({ timeSlot, dayOfWeek }, index) => (
                <span key={index}>
                  {`${t("timetable.weekDay." + getWeekDay(dayOfWeek) + ".label")} (${timeSlot})`}
                  &nbsp;|&nbsp;
                </span>
              ))}
          </Typography>
          <TextField
            type="number"
            disabled={addStudentDetails.status === "STUDENT_TRIAL"}
            margin="dense"
            id="name"
            label={t("addStudent.placeholder.sessions")}
            value={addStudentDetails.sessions}
            onChange={(e) => updateFieldValue("sessions", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem" }}
          />
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ paddingX: "0.5rem", fontWeight: "bold", width: "30%" }}>
            {t("addStudent.placeholder.applySchedule")}:
          </Typography>
          <Typography sx={{ paddingX: "0.5rem" }}>
            {t("addStudent.description.applySchedule")}
          </Typography>
        </Grid>
        {(schedule || []).map(
          ({ teacherName, timeSlot, dayOfWeek, classroomName, uuid }, index) => (
            <Grid
              key={index}
              item
              sx={{ display: "flex", alignItems: "center", padding: "0 !important" }}>
              <FormControlLabel
                sx={{ paddingX: "0.5rem", marginRight: 0 }}
                value={uuid}
                control={<Checkbox defaultChecked />}
                onChange={(e) => {
                  const appliedScheduleUuids = [...addStudentDetails.classScheduleUuids];
                  const index = appliedScheduleUuids.indexOf(e.target.value);
                  if (e.target.checked && index === -1) {
                    appliedScheduleUuids.push(e.target.value);
                  } else if (!e.target.checked && index !== -1) {
                    appliedScheduleUuids.splice(index, 1);
                  }
                  updateFieldValue("classScheduleUuids", appliedScheduleUuids);
                }}
                label={`${teacherName} / ${timeSlot} ${t(
                  "timetable.weekDay." + getWeekDay(dayOfWeek) + ".label"
                )} (${classroomName})`}
              />
            </Grid>
          )
        )}
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("addStudent.placeholder.note")}
            value={addStudentDetails.note}
            onChange={(e) => updateFieldValue("note", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem" }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AddStudentDialog;
