import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";
import { branchInfo } from "../../../signals";

const DEFAULT_CLASSROOM_ID = "SW_PH_";

const INITIAL_DATA = {
  classroomId: DEFAULT_CLASSROOM_ID + new Date().getTime(),
  classroomName: "",
  classroomType: "OFFICE",
  description: "",
  status: "ACTIVE"
};

const ClassroomDetailsDialog = ({ data, onConfirm, ...props }) => {
  const { t } = useTranslation();

  const [classroomDetails, setClassroomDetails] = useState({
    ...INITIAL_DATA,
    classroomId: branchInfo.value.branchId + "_PH_" + new Date().getTime()
  });

  const updateFieldValue = (fieldName, fieldValue) => {
    setClassroomDetails({ ...classroomDetails, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if (data) {
      setClassroomDetails({
        ...data,
        branchUuid: branchInfo.value.uuid,
        branchName: branchInfo.value.branchName
      });
    } else {
      setClassroomDetails({
        ...INITIAL_DATA,
        classroomId: branchInfo.value.branchId + "_PH_" + new Date().getTime()
      });
    }
  }, [data]);

  const confirmSaveClassroom = () => {
    const formData = {
      ...classroomDetails,
      branchUuid: branchInfo.value.uuid,
      branchName: branchInfo.value.branchName
    };
    onConfirm(formData);
    setClassroomDetails({
      ...INITIAL_DATA,
      classroomId: branchInfo.value.branchId + "_PH_" + new Date().getTime()
    });
  };

  return (
    <Dialog {...props} onConfirm={confirmSaveClassroom} width="1000px">
      <Grid
        container
        p="1rem"
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                onChange={(e, value) => {
                  setClassroomDetails({
                    ...classroomDetails,
                    branchUuid: branchInfo.value.uuid || "",
                    branchName: branchInfo.value.branchName || ""
                  });
                }}
                disabled
                value={{
                  uuid: branchInfo.value.uuid,
                  branchName: branchInfo.value.branchName
                }}
                placeholder="classroomDetails.placeholder.branchName"
                requestConfig={{
                  url: "v1/admin/branches",
                  label: "branchName",
                  value: "uuid"
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="status-label" color="neutral">
                {t("classroomDetails.placeholder.status")}
              </InputLabel>
              <Select
                labelId="status-label"
                id="status"
                label={t("classroomDetails.placeholder.status")}
                MenuProps={{
                  style: { zIndex: 15003 }
                }}
                disabled={!data}
                value={classroomDetails.status}
                onChange={(e) => updateFieldValue("status", e.target.value)}
                sx={{ width: "100%" }}>
                <MenuItem value="ACTIVE">{t("classrooms.status.ACTIVE.label")}</MenuItem>
                <MenuItem value="INACTIVE">{t("classrooms.status.INACTIVE.label")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("classroomDetails.placeholder.classroomName")}
              value={classroomDetails.classroomName || ""}
              onChange={(e) => updateFieldValue("classroomName", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="status-label" color="neutral">
                {t("classroomDetails.placeholder.classroomType")}
              </InputLabel>
              <Select
                labelId="status-label"
                id="status"
                label={t("classroomDetails.placeholder.classroomType")}
                MenuProps={{
                  style: { zIndex: 15003 }
                }}
                value={classroomDetails.classroomType}
                onChange={(e) => updateFieldValue("classroomType", e.target.value)}
                sx={{ width: "100%" }}>
                <MenuItem value="OFFICE">{t("classrooms.type.OFFICE")}</MenuItem>
                <MenuItem value="ONLINE">{t("classrooms.type.ONLINE")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <TextField
            margin="dense"
            label={t("classroomDetails.placeholder.description")}
            value={classroomDetails.description || ""}
            onChange={(e) => updateFieldValue("description", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem" }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ClassroomDetailsDialog;
