import RequestHeaders from "../constants/RequestHeaders";
import axios from "axios";
import { errorHandler, requestHandler, responseHandler } from "./interceptors";
import { globalLoading } from "../signals";

export const BASE_URL = "https://api.crmstarsworld.online/sw-mgmt/service/";

export const httpClient = axios.create({
  timeout: 600000,
  headers: {
    [RequestHeaders.CONTENT_TYPE]: "application/json;charset=utf-8",
    [RequestHeaders.ACESS_CONTROLL_ALLOW_HEADERS]: "*",
    [RequestHeaders.ACESS_CONTROLL_ALLOW_ORIGIN]: "*",
    [RequestHeaders.ACESS_CONTROLL_ALLOW_METHODS]: "POST,GET,PUT,DELETE,OPTIONS",
    [RequestHeaders.CUSTOM_ERROR_HANDLER]: "false"
  }
});

httpClient.interceptors.request.use(requestHandler);
httpClient.interceptors.response.use(responseHandler, errorHandler);

export const get = (path, paramsObj = {}, isFullUrl = false) => {
  return httpClient.get(toUrl(path, !isFullUrl), {
    params: paramsObj
  });
};

export const getWithoutLoading = (path, paramsObj = {}, isFullUrl = false) => {
  globalLoading.value = false;
  return httpClient.get(!isFullUrl ? `${BASE_URL}${path}` : path, {
    params: paramsObj
  });
};

export const getById = (path, id, isFullUrl = false) => {
  return httpClient.get(`${toUrl(path, !isFullUrl)}/${id}`);
};

export const post = (path, body = {}, isFullUrl = false) => {
  return httpClient.post(toUrl(path, !isFullUrl), { ...body });
};

export const postWithHeader = (path, headers, body, isFullUrl = false) => {
  return httpClient.post(toUrl(path, !isFullUrl), body, { headers });
};

export const put = (path, body = {}, isFullUrl = false) => {
  return httpClient.put(toUrl(path, !isFullUrl), { ...body });
};

export const deleteById = (path, id, isFullUrl = false) => {
  return httpClient.delete(`${toUrl(path, !isFullUrl)}/${id}`);
};

const toUrl = (input, baseUrlRequired) => {
  globalLoading.value = true;
  return baseUrlRequired ? `${BASE_URL}${input}` : input;
};
