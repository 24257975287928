import styled from "@emotion/styled";
import { Edit } from "@mui/icons-material";
import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import SendIcon from "@mui/icons-material/Send";

import {
  Box,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";
import Autocomplete from "../../../components/autocomplete";
import { Button } from "../../../components/button";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import { get, getWithoutLoading, post, put } from "../../../services/HttpClient";
import { branchInfo, renewInvoice } from "../../../signals";
import { tokens } from "../../../theme";
import { to_vietnamese } from "../../../utils/CurencyUtil";
import { getDisplayName, getStudentDetailLabel } from "../../../utils/UserUtil";

const INITIAL_PAYMENT_CONTRACT = {
  creationDate: moment(),
  contractType: "RENEW",
  studentUuid: "",
  studentValue: {
    uuid: "",
    displayName: ""
  },
  totalAmount: 0,
  payAmount: 0,
  discount: 0,
  paidAmount: 0,
  paidDate: moment(),
  paymentMethod: "INSTALLMENT"
};

const NEW_COURSE_PRODUCT = {
  singlePrice: 0,
  quantity: 0,
  totalAmount: 0,
  discount: 0,
  payAmount: 0,
  classValue: {
    uuid: "",
    className: ""
  }
};

const NEW_INSTALLMENT = {
  paidAmount: 0,
  ordinal: 0,
  status: "ACTIVE",
  note: ""
};

const INITIAL_REGISTERED_CLASS = {
  classUuid: "",
  classValue: {
    uuid: "",
    className: ""
  },
  registeredSessions: 0,
  startDate: moment(),
  endDate: moment(),
  description: ""
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

const PaymentContract = () => {
  const { invoiceUuid } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [data, setData] = useState([{ ...NEW_COURSE_PRODUCT, index: 0 }]);
  const [installments, setInstallments] = useState([{ ...NEW_INSTALLMENT, ordinal: 0 }]);
  const [selectedRow, setSelectedRow] = useState(INITIAL_REGISTERED_CLASS);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState({
    ...INITIAL_PAYMENT_CONTRACT,
    contractId: branchInfo.value.branchId + "_HĐ_" + new Date().getTime()
  });
  const [initProcessing, setInitProcessing] = useState(true);
  const [discount, setDiscount] = useState(null);
  const [settlement, setSettlement] = useState(false);
  const [potentialTimeline, setPotentialTimeline] = useState([]);
  const [selectedTimelineIndexes, setSelectedTimelineIndexes] = useState([]);
  const { t } = useTranslation();

  const handleRowChange = (updatedData) => {
    const updatedRow = { ...selectedRow, ...updatedData };
    setSelectedRow({ ...updatedRow });
  };

  useEffect(() => {
    if (branchInfo.value) {
      if (!invoiceUuid || invoiceUuid === "new") {
        resetData();
      } else {
        refreshData();
      }
    }
  }, [branchInfo.value, invoiceUuid]);

  useEffect(() => {
    if (!initProcessing) {
      const totalAmount = data
        .filter(({ packageValue }) => !packageValue?.packageType?.includes("DISCOUNT"))
        .reduce((amount, row) => amount + row.totalAmount, 0);
      const discount = data
        .filter(({ packageValue }) => !packageValue?.packageType?.includes("DISCOUNT"))
        .reduce((amount, row) => amount + row.discount, 0);
      const payAmount = data
        .filter(({ packageValue }) => !packageValue?.packageType?.includes("DISCOUNT"))
        .reduce((amount, row) => amount + row.payAmount, 0);
      const debtAmount = calculateRemainingDebt(
        paymentRequest.paymentMethod,
        payAmount,
        paymentRequest.paidAmount,
        installments
      );
      setPaymentRequest({
        ...paymentRequest,
        totalAmount,
        discount,
        payAmount,
        payAmountLetters: to_vietnamese(payAmount),
        debtAmount
      });
    }
  }, [data, installments]);

  useEffect(() => {
    if (!initProcessing) {
      const updatedData = [...data];
      updatedData
        .filter(({ packageValue }) => packageValue?.packageType?.includes("DISCOUNT"))
        .forEach((data) => {
          const { packageValue } = data;
          const discountValue =
            packageValue?.packageType === "DISCOUNT_PERCENTAGE"
              ? packageValue?.singlePrice * 100
              : packageValue?.singlePrice;
          const discountPrice =
            packageValue?.packageType === "DISCOUNT_PERCENTAGE"
              ? -(paymentRequest.totalAmount * packageValue?.singlePrice)
              : -packageValue?.singlePrice;
          data.singlePrice = discountValue;
          data.totalAmount = discountPrice;
          data.discount = -discountPrice;
          data.payAmount = discountPrice;
        });
      setData(updatedData);
    }
  }, [paymentRequest.totalAmount]);

  const resetData = () => {
    if (renewInvoice.value) {
      const { isSettlement, registeredSessions, packageValue, classValue, studentValue } =
        renewInvoice.value;
      setSettlement(isSettlement);
      // round up money
      const totalAmount = packageValue
        ? Number((registeredSessions * packageValue.singlePrice).toFixed(0))
        : 0;
      const packageDetails = packageValue
        ? {
            index: 0,
            classUuid: classValue?.uuid,
            packageUuid: packageValue?.uuid,
            registeredSessions,
            totalAmount,
            payAmount: totalAmount,
            discount: 0,
            classValue,
            ...packageValue,
            packageValue
          }
        : {
            index: 0,
            classUuid: classValue?.uuid,
            registeredSessions,
            singlePrice: 0,
            quantity: 0,
            totalAmount,
            payAmount: totalAmount,
            discount: 0,
            classValue
          };
      setData([packageDetails]);
      setPaymentRequest({
        ...paymentRequest,
        contractType: "RENEW",
        paymentMethod: "BANK_TRANSFER",
        totalAmount,
        debtAmount: totalAmount,
        studentUuid: studentValue.uuid,
        studentValue
      });
    } else {
      setData([{ ...NEW_COURSE_PRODUCT, index: 0 }]);
      setSelectedRow(INITIAL_REGISTERED_CLASS);
      setPaymentRequest({
        ...INITIAL_PAYMENT_CONTRACT,
        ...paymentRequest,
        contractId: branchInfo.value.branchId + "_HĐ_" + new Date().getTime()
      });
    }
    setInitProcessing(false);
  };

  const refreshData = () => {
    get(`v1/admin/invoices/${invoiceUuid}`)
      .then((res) => {
        const totalAmount = (res.orders || []).reduce((amount, row) => amount + row.totalAmount, 0);
        const discount = (res.orders || []).reduce((amount, row) => amount + row.discount, 0);
        const payAmount = (res.orders || []).reduce((amount, row) => amount + row.payAmount, 0);
        const debtAmount = calculateRemainingDebt(
          res.paymentMethod,
          payAmount,
          res.paidAmount,
          res.installments || []
        );
        setInstallments(res.installments || []);
        setData(
          (res.orders || []).map((x, index) => ({
            ...x,
            ...x.packageValue,
            startDate: moment(x.startDate),
            endDate: moment(x.endDate),
            registeredSessions: x.packageValue?.quantity || 0,
            index
          }))
        );
        setPaymentRequest({
          ...res,
          creationDate: moment(new Date(res.creationDate)),
          paidDate: moment(new Date(res.paidDate)),
          totalAmount,
          discount,
          payAmount,
          payAmountLetters: to_vietnamese(payAmount),
          debtAmount
        });
        setInitProcessing(false);
      })
      .catch(console.debug);
  };

  const resetDialogForm = () => {
    setSelectedRow(INITIAL_REGISTERED_CLASS);
    setPotentialTimeline([]);
    setSelectedTimelineIndexes([]);
  };

  const calculateRemainingDebt = (paymentMethod, payAmount, paidAmount, installments) => {
    let debtAmount;
    if (paymentMethod === "INSTALLMENT") {
      const totalPaidAmount = installments
        .filter(({ status }) => status === "ACTIVE" || status === "COMPLETED")
        .map(({ paidAmount }) =>
          parseInt(paidAmount.toString().replaceAll(",", "").replaceAll(" đ", ""))
        )
        .reduce((totalPaid, amount) => {
          totalPaid += amount || 0;
          return totalPaid;
        }, 0);
      debtAmount = payAmount - totalPaidAmount;
    } else {
      debtAmount = payAmount - (paidAmount || 0);
    }
    // to avoid decimal point, debtAmount can be < 0
    return debtAmount <= 0 ? 0 : debtAmount;
  };

  const savePaymentContract = (saveAsDraft, exportDisabled) => {
    const invoiceRequest = {
      ...paymentRequest,
      branchUuid: branchInfo.value.uuid,
      saveAsDraft,
      orders: data
    };
    if (paymentRequest.paymentMethod === "INSTALLMENT") {
      invoiceRequest.installments = installments.map((x) => ({
        ...x,
        paidAmount: parseInt(x.paidAmount.toString().replaceAll(",", "").replaceAll(" đ", ""))
      }));
    } else {
      invoiceRequest.paidAmount = paymentRequest.paidAmount
        ? parseInt(paymentRequest.paidAmount.toString().replaceAll(",", "").replaceAll(" đ", ""))
        : 0;
    }

    if (!invoiceUuid || invoiceUuid === "new") {
      post("v1/admin/invoices", invoiceRequest)
        .then((uuid) => {
          return get(`v1/admin/invoices/${uuid}/export`);
        })
        .then((base64Str) => {
          if (!exportDisabled) {
            saveInvoiceAsDocx(base64Str, paymentRequest.studentValue.niceName);
          }
          navigate("../../invoices");
          setPaymentRequest({
            ...INITIAL_PAYMENT_CONTRACT,
            contractId: branchInfo.value.branchId + "_HĐ_" + new Date().getTime()
          });
        })
        .catch(console.debug);
    } else {
      put(`v1/admin/invoices/${invoiceUuid}`, invoiceRequest)
        .then((base64Str) => {
          if (!exportDisabled) {
            saveInvoiceAsDocx(base64Str, paymentRequest.studentValue.niceName);
          }
          navigate("../../invoices");
          setPaymentRequest({
            ...INITIAL_PAYMENT_CONTRACT,
            contractId: branchInfo.value.branchId + "_HĐ_" + new Date().getTime()
          });
        })
        .catch(console.debug);
    }
  };

  const saveInvoiceAsDocx = (base64Str, studentName) => {
    const link = document.createElement("a");
    link.href = `data:text/docx;base64,${base64Str}`;
    link.download =
      t("invoices.fileDownload.name", {
        studentName,
        creationDate: moment().format("DD-MM-YYYY")
      }) + ".docx";
    link.click();
  };

  const setPackageDetailsToRow = (rowData, packageValue) => {
    const { uuid, packageType, singlePrice, quantity, totalPrice } = packageValue;
    if (packageType.includes("DISCOUNT")) {
      const discountValue = packageType === "DISCOUNT_PERCENTAGE" ? singlePrice * 100 : singlePrice;
      const discountPrice =
        packageType === "DISCOUNT_PERCENTAGE"
          ? -(paymentRequest.totalAmount * singlePrice)
          : -singlePrice;
      return {
        ...rowData,
        packageUuid: uuid,
        singlePrice: discountValue,
        quantity,
        totalAmount: discountPrice,
        payAmount: discountPrice,
        discount: -discountPrice,
        packageValue
      };
    } else {
      return {
        ...rowData,
        packageUuid: uuid,
        singlePrice,
        quantity,
        registeredSessions: quantity,
        totalAmount: totalPrice,
        payAmount: totalPrice,
        packageValue
      };
    }
  };

  const getDiscountValue = (data) => {
    const { discount, packageValue } = data;
    if (packageValue?.packageType === "DISCOUNT_PERCENTAGE") {
      return packageValue?.singlePrice * 100;
    } else if (packageValue?.packageType === "DISCOUNT_AMOUNT") {
      return packageValue?.singlePrice;
    }
    return discount || 0;
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Stars World Online Management" />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              value={paymentRequest.contractId}
              onChange={(e) => setPaymentRequest({ ...paymentRequest, contractId: e.target.value })}
              label={t("paymentContract.placeholder.contractId")}
              variant="outlined"
              sx={{ width: "100%", paddingX: "0.5rem" }}
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label={t("paymentContract.placeholder.creationDate")}
                format="DD/MM/YYYY"
                value={paymentRequest.creationDate}
                onChange={(e) => setPaymentRequest({ ...paymentRequest, creationDate: e })}
                sx={{ width: "100%", padding: "0 0.5rem" }}
                timeSteps={{ hours: 1, minutes: 15 }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="class-status-label" color="neutral">
                {t("paymentContract.placeholder.contractType")}
              </InputLabel>
              <Select
                labelId="class-status-label"
                id="class-status"
                label={t("paymentContract.placeholder.contractType")}
                MenuProps={{
                  style: { zIndex: 15003 }
                }}
                value={paymentRequest.contractType}
                onChange={(e) => {
                  setPaymentRequest({ ...paymentRequest, contractType: e.target.value });
                }}
                sx={{ width: "100%" }}>
                <MenuItem value="RENEW">{t("paymentContract.contractType.RENEW")}</MenuItem>
                <MenuItem value="NEW">{t("paymentContract.contractType.NEW")}</MenuItem>
                <MenuItem value="VIRTUAL_CONTRACT">
                  {t("paymentContract.contractType.VIRTUAL_CONTRACT")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="paymentContract.placeholder.student"
                value={paymentRequest.studentValue}
                onChange={(e, value) => {
                  if (value) {
                    setPaymentRequest({
                      ...paymentRequest,
                      studentUuid: value.uuid || "",
                      studentValue: value
                    });
                  } else {
                    setPaymentRequest({
                      ...paymentRequest,
                      studentUuid: "",
                      studentValue: {
                        uuid: "",
                        displayName: getStudentDetailLabel(null)
                      }
                    });
                  }
                }}
                requestConfig={{
                  url: `v1/students?branchUuid=${branchInfo.value.uuid}`,
                  responseField: "results",
                  label: (student) => getStudentDetailLabel(student),
                  value: "uuid",
                  onQueryChange: (value) => (value ? `&any=${value}` : "")
                }}></Autocomplete>
            </FormControl>
          </Grid>
        </Grid>
        {paymentRequest.studentValue?.parent && (
          <Grid container mb="1rem" columnSpacing={1}>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center", marginX: "0.5rem" }}>
              <Typography component="label" fontWeight="bold">
                {t("paymentContract.studentBirthday.label")}:&nbsp;
              </Typography>
              <Typography>
                {paymentRequest.studentValue.birthday
                  ? moment(new Date(paymentRequest.studentValue.birthday)).format("DD/MM/YYYY")
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center", marginX: "0.5rem" }}>
              <Typography component="label" fontWeight="bold">
                {t("paymentContract.parentInfo.label")}:&nbsp;
              </Typography>
              <Typography>
                {getDisplayName(paymentRequest.studentValue.parent)}
                &nbsp;/&nbsp;
                {paymentRequest.studentValue.parent?.mobile
                  ? "0" + paymentRequest.studentValue.parent.mobile
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        )}
        <Grid container direction="row-reverse" columnSpacing={1}></Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  {t("paymentContract.table.field.courseOrProduct.label")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {paymentRequest.contractType === "VIRTUAL_CONTRACT"
                    ? t("paymentContract.table.field.addLinkedClass.label")
                    : t("paymentContract.table.field.addClass.label")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t("paymentContract.table.field.singlePrice.label")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t("paymentContract.table.field.quantity.label")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t("paymentContract.table.field.totalAmount.label")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t("paymentContract.table.field.discount.label")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t("paymentContract.table.field.payAmount.label")}
                </StyledTableCell>
                <StyledTableCell align="center">{t("table.actions.label")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((rowData, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row" align="center">
                    {rowData.packageValue?.packageType?.includes("DISCOUNT") ? (
                      <Typography sx={{ width: "100%", textAlign: "start", paddingX: "1.5rem" }}>
                        {rowData.packageValue.packageName}
                      </Typography>
                    ) : (
                      <FormControl
                        sx={{ width: "100%", minWidth: "200px", paddingX: "0.5rem" }}
                        size="small">
                        <Autocomplete
                          placeholder="paymentContract.placeholder.courseOrProduct"
                          disabled={settlement}
                          value={rowData.packageValue || { uuid: "", packageName: "" }}
                          onChange={(e, value) => {
                            const rowIndex = data.findIndex((x, index) => index === rowData.index);
                            const updatedList = [...data];
                            let updatedRow;
                            if (value) {
                              updatedRow = setPackageDetailsToRow(rowData, value);
                            } else {
                              updatedRow = {
                                ...rowData,
                                packageUuid: "",
                                singlePrice: 0,
                                quantity: 0,
                                registeredSessions: 0,
                                totalAmount: 0,
                                payAmount: 0,
                                packageValue: { uuid: "", packageName: "" }
                              };
                            }
                            updatedList.splice(rowIndex, 1, updatedRow);
                            setData(updatedList);
                          }}
                          requestConfig={{
                            url: `v1/admin/packages?branchUuid=${branchInfo.value.uuid}&status=ACTIVE&types=COURSE,ITEM`,
                            label: "packageName",
                            value: "uuid"
                          }}></Autocomplete>
                      </FormControl>
                    )}
                  </StyledTableCell>
                  {rowData.packageValue?.packageType === "COURSE" ||
                  rowData.packageValue?.packageType === "ITEM" ? (
                    <>
                      <StyledTableCell align="center">
                        {rowData.classUuid ? (
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <Typography>{rowData.classValue.className}</Typography>
                            <IconButton
                              color="success"
                              disabled={settlement}
                              onClick={() => setSelectedRow({ ...selectedRow, ...rowData })}>
                              <Edit />
                            </IconButton>
                          </Box>
                        ) : (
                          rowData.packageUuid &&
                          rowData.packageValue?.packageType === "COURSE" && (
                            <>
                              {!paymentRequest.studentUuid ? (
                                <AddCircleOutlineIcon
                                  aria-owns={anchorEl ? "mouse-over-popover" : undefined}
                                  aria-haspopup="true"
                                  color="disabled"
                                  onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
                                  onMouseLeave={() => setAnchorEl(null)}
                                />
                              ) : (
                                <IconButton
                                  color="success"
                                  aria-owns={!anchorEl ? undefined : "warning-popover"}
                                  onClick={(e) => setSelectedRow({ ...selectedRow, ...rowData })}>
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              )}
                              <Popover
                                id="mouse-over-popover"
                                sx={{
                                  pointerEvents: "none"
                                }}
                                open={!!anchorEl}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center"
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left"
                                }}
                                onClose={() => setAnchorEl(null)}
                                disableRestoreFocus>
                                <Typography sx={{ p: 2 }}>
                                  {t("paymentContract.hint.studentRequired")}
                                </Typography>
                              </Popover>
                            </>
                          )
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {rowData.singlePrice
                          ? rowData.singlePrice.toLocaleString("en-US", {
                              style: "currency",
                              currency: "VND",
                              currencyDisplay: "symbol"
                            })
                          : 0}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {settlement ? rowData.registeredSessions : rowData.quantity || 0}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {rowData.totalAmount
                          ? rowData.totalAmount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "VND",
                              currencyDisplay: "symbol"
                            })
                          : 0}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <NumericFormat
                          margin="dense"
                          disabled={
                            settlement || rowData.packageValue?.packageType?.includes("DISCOUNT")
                          }
                          value={getDiscountValue(rowData)}
                          onChange={(e) => {
                            const discountValue = parseInt(
                              e.target.value.replaceAll(",", "").replaceAll(" đ", "")
                            );
                            const rowIndex = data.findIndex((x, index) => index === rowData.index);
                            const updatedList = [...data];
                            const updatedRow = {
                              ...rowData,
                              discount: discountValue,
                              payAmount: rowData.totalAmount - discountValue
                            };
                            updatedList.splice(rowIndex, 1, updatedRow);
                            setData(updatedList);
                          }}
                          fullWidth
                          size="small"
                          variant="outlined"
                          sx={{ paddingX: "0.5rem", margin: 0 }}
                          customInput={TextField}
                          allowLeadingZeros
                          thousandSeparator=","
                          suffix={
                            rowData.packageValue?.packageType === "DISCOUNT_PERCENTAGE"
                              ? " %"
                              : " đ"
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {rowData.payAmount
                          ? rowData.payAmount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "VND",
                              currencyDisplay: "symbol"
                            })
                          : 0}
                      </StyledTableCell>
                    </>
                  ) : (
                    <StyledTableCell colSpan={6}></StyledTableCell>
                  )}
                  <StyledTableCell align="center">
                    {index > 0 && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          const updatedList = [...data];
                          updatedList.splice(index, 1);
                          setData(updatedList);
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <FormControl
                sx={{ minWidth: "200px", paddingLeft: "1.5rem", paddingRight: "0.5rem" }}
                size="small">
                <Autocomplete
                  placeholder="paymentContract.placeholder.discount"
                  value={discount || { uuid: "", packageName: "" }}
                  onChange={(e, value) => {
                    setDiscount(value);
                  }}
                  requestConfig={{
                    url: `v1/admin/packages?branchUuid=${branchInfo.value.uuid}&status=ACTIVE&types=DISCOUNT_AMOUNT,DISCOUNT_PERCENTAGE`,
                    label: "packageName",
                    value: "uuid"
                  }}></Autocomplete>
              </FormControl>
              <Button
                disabled={!discount}
                variant="contained"
                color="secondary"
                onClick={() => {
                  const discountPackage = setPackageDetailsToRow(
                    { ...NEW_COURSE_PRODUCT, index: data.length },
                    discount
                  );
                  const updatedData = [...data, discountPackage];
                  updatedData[0].discount += discountPackage.discount;
                  updatedData[0].payAmount = updatedData[0].totalAmount - updatedData[0].discount;
                  setData(updatedData);
                  setDiscount(null);
                }}
                sx={{ textTransform: "none" }}>
                {t("paymentContract.button.addDiscount.label")}
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <IconButton
                color="secondary"
                size="large"
                sx={{ margin: "0.5rem" }}
                aria-label="add an item"
                onClick={() => {
                  setData([...data, { ...NEW_COURSE_PRODUCT, index: data.length }]);
                }}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        </TableContainer>
      </Box>
      <Box display="flex" flexDirection="column">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}></Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography component="label" fontWeight="bold" marginRight="1rem">
              {t("paymentContract.conclusion.totalAmount.label")}:
            </Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography>
              {paymentRequest.totalAmount?.toLocaleString("en-US", {
                style: "currency",
                currency: "VND"
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}></Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography component="label" fontWeight="bold" marginRight="1rem">
              {t("paymentContract.conclusion.otherDiscount.label")}:
            </Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography>
              {paymentRequest.discount?.toLocaleString("en-US", {
                style: "currency",
                currency: "VND"
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}></Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography component="label" fontWeight="bold" marginRight="1rem">
              {t("paymentContract.conclusion.payAmount.label")}:
            </Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography>
              {paymentRequest.payAmount?.toLocaleString("en-US", {
                style: "currency",
                currency: "VND"
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}></Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography component="label" fontWeight="bold" marginRight="1rem">
              {t("paymentContract.conclusion.payAmountLetters.label")}:
            </Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography component="i">{paymentRequest.payAmountLetters}</Typography>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}></Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography component="label" fontWeight="bold" marginRight="1rem">
              {t("paymentContract.conclusion.paymentMethod.label")}:
            </Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" sx={{ paddingX: "0 !important" }}>
            <FormControl sx={{ width: "100%", minWidth: "100px" }} size="small">
              <Select
                labelId="class-status-label"
                id="class-status"
                disabled={!!invoiceUuid}
                MenuProps={{
                  style: { zIndex: 15003 }
                }}
                value={paymentRequest.paymentMethod}
                onChange={(e) => {
                  setPaymentRequest({ ...paymentRequest, paymentMethod: e.target.value });
                }}
                sx={{ width: "100%" }}>
                <MenuItem value="CASH">{t("paymentContract.paymentMeethod.CASH.label")}</MenuItem>
                <MenuItem value="BANK_TRANSFER">
                  {t("paymentContract.paymentMeethod.BANK_TRANSFER.label")}
                </MenuItem>
                <MenuItem value="MASTERCARD">
                  {t("paymentContract.paymentMeethod.MASTERCARD.label")}
                </MenuItem>
                <MenuItem value="INSTALLMENT">
                  {t("paymentContract.paymentMeethod.INSTALLMENT.label")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {paymentRequest.paymentMethod !== "INSTALLMENT" && (
          <Grid container mb="1rem" columnSpacing={1}>
            <Grid item xs={6}></Grid>
            <Grid item xs={3} display="flex" alignItems="center">
              <Typography component="label" fontWeight="bold" marginRight="1rem">
                {t("paymentContract.conclusion.paidAmount.label")}:
              </Typography>
            </Grid>
            <Grid item xs={3} display="flex" alignItems="center" sx={{ paddingX: "0 !important" }}>
              <NumericFormat
                margin="dense"
                value={paymentRequest.paidAmount || ""}
                onChange={(e) => {
                  setPaymentRequest({
                    ...paymentRequest,
                    paidAmount: e.target.value,
                    debtAmount:
                      paymentRequest.payAmount -
                      (Number(e.target.value.replaceAll(",", "").replaceAll(" đ", "")) || 0)
                  });
                }}
                fullWidth
                size="small"
                variant="outlined"
                sx={{ margin: 0 }}
                customInput={TextField}
                allowLeadingZeros
                thousandSeparator=","
                suffix=" đ"
              />
            </Grid>
          </Grid>
        )}
        {paymentRequest.paymentMethod === "INSTALLMENT" && (
          <>
            {installments.map((installment, index) => (
              <Grid container key={index} mb="1rem" columnSpacing={1}>
                <Grid item xs={6}></Grid>
                <Grid item xs={3} display="flex" alignItems="center">
                  {installment.ordinal === 0 && (
                    <Typography component="label" fontWeight="bold" marginRight="1rem">
                      {t("paymentContract.conclusion.paidAmount.label")}:
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  display="flex"
                  alignItems="center"
                  sx={{ paddingX: "0 !important" }}>
                  <NumericFormat
                    margin="dense"
                    value={installment.paidAmount || ""}
                    disabled={installment.status === "COMPLETED"}
                    onChange={(e) => {
                      const updatedList = [...installments];
                      updatedList.splice(index, 1, {
                        ...installment,
                        paidAmount: e.target.value
                      });
                      setInstallments(updatedList);
                    }}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{ margin: 0 }}
                    customInput={TextField}
                    allowLeadingZeros
                    thousandSeparator=","
                    suffix=" đ"
                  />
                </Grid>
                <Grid
                  item
                  xs={1.2}
                  display="flex"
                  alignItems="center"
                  sx={{ paddingX: "0 !important" }}>
                  <TextField
                    multiline
                    maxRows={4}
                    disabled={installment.status === "COMPLETED"}
                    value={installment.note || ""}
                    onChange={(e) => {
                      const updatedList = [...installments];
                      updatedList.splice(index, 1, {
                        ...installment,
                        note: e.target.value
                      });
                      setInstallments(updatedList);
                    }}
                    margin="dense"
                    id="name"
                    label={t("paymentContract.installmentAmount.label")}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{ paddingLeft: "0.5rem", margin: 0 }}
                  />
                </Grid>
                <Grid
                  item
                  xs={0.3}
                  display="flex"
                  alignItems="center"
                  sx={{ paddingX: "0 !important" }}>
                  {installment.ordinal > 0 && installment.status !== "COMPLETED" && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        const updatedList = [...installments];
                        updatedList.splice(index, 1);
                        updatedList.forEach((row, i) => (row.ordinal = i));
                        setInstallments(updatedList);
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
            <Grid container mb="1rem" columnSpacing={1}>
              <Grid item xs={6}></Grid>
              <Grid item xs={3} display="flex" alignItems="center"></Grid>
              <Grid
                item
                xs={3}
                sx={{
                  textAlign: "end"
                }}>
                <IconButton
                  color="success"
                  onClick={() => {
                    setInstallments([
                      ...installments,
                      { ...NEW_INSTALLMENT, ordinal: installments.length }
                    ]);
                  }}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          </>
        )}
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}></Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography component="label" fontWeight="bold" marginRight="1rem">
              {t("paymentContract.conclusion.debtAmount.label")}:
            </Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography>
              {paymentRequest.debtAmount?.toLocaleString("en-US", {
                style: "currency",
                currency: "VND"
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}></Grid>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography component="label" fontWeight="bold" marginRight="1rem">
              {t("paymentContract.conclusion.paidDate.label")}:
            </Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" sx={{ paddingX: "0 !important" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="paid-date"
                format="DD/MM/YYYY"
                sx={{ width: "100%" }}
                value={paymentRequest.paidDate || moment()}
                onChange={(e) => {
                  setPaymentRequest({
                    ...paymentRequest,
                    paidDate: e
                  });
                }}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" flexDirection="column">
        <Grid container mb="1rem" columnSpacing={1}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6} display="flex" alignItems="center" justifyContent="end">
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
              <Button
                variant="contained"
                color="neutral"
                startIcon={<RemoveCircleIcon />}
                onClick={() => {
                  if (invoiceUuid && invoiceUuid !== "new") {
                    navigate(-1);
                  } else {
                    setPaymentRequest({
                      ...INITIAL_PAYMENT_CONTRACT,
                      contractId: branchInfo.value.branchId + "_HĐ_" + new Date().getTime()
                    });
                    setData([{ ...NEW_COURSE_PRODUCT, index: 0 }]);
                    setSelectedRow(INITIAL_REGISTERED_CLASS);
                  }
                }}>
                {t("paymentContract.button.cancel")}
              </Button>
              <Button
                variant="contained"
                disabled={!paymentRequest?.studentUuid}
                color="warning"
                startIcon={<SaveAsIcon />}
                onClick={() => savePaymentContract(true, true)}>
                {t("paymentContract.button.saveDraft")}
              </Button>
              <Button
                variant="contained"
                disabled={!paymentRequest?.studentUuid}
                color="success"
                startIcon={<SendIcon />}
                onClick={() => savePaymentContract(false, true)}>
                {t("paymentContract.button.proceedAndSave")}
              </Button>
              <Button
                variant="contained"
                disabled={!paymentRequest?.studentUuid}
                color="success"
                startIcon={<SendAndArchiveIcon />}
                onClick={() => savePaymentContract(false, false)}>
                {t("paymentContract.button.proceedAndExport")}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={selectedRow.index != null && selectedRow.index !== undefined}
        onClose={() => {
          resetDialogForm();
        }}
        onConfirm={() => {
          setPaymentRequest({
            ...paymentRequest,
            sessionsAddedUuids:
              paymentRequest.contractType === "VIRTUAL_CONTRACT"
                ? potentialTimeline
                    .filter((x, index) => selectedTimelineIndexes.includes(index))
                    .map(({ uuid }) => uuid)
                : null
          });
          const updatedList = [...data];
          updatedList.splice(selectedRow.index, 1, selectedRow);
          setData(updatedList);
          resetDialogForm();
        }}
        buttons={DialogButtons.CANCEL_CONFIRM}
        title={t("paymentContract.dialog.addClass.title")}>
        <Grid
          container
          p="1rem"
          flexDirection="column"
          justifyContent="center"
          sx={{
            width: "100%"
          }}
          rowSpacing={4}>
          <Grid item>
            <FormControl sx={{ width: "100%", minWidth: "150px", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="paymentContract.placeholder.classSearch"
                value={selectedRow.classValue}
                onChange={(e, value) => {
                  if (value) {
                    get(
                      `v1/classes/${value.uuid}/potentialTimeline?studentUuid=${paymentRequest.studentUuid}&registeredSessions=${selectedRow.registeredSessions}&contractType=${paymentRequest.contractType}`
                    )
                      .then((res) => {
                        if (paymentRequest.contractType === "VIRTUAL_CONTRACT") {
                          setPotentialTimeline(res);
                          setSelectedTimelineIndexes([0]);
                        }
                        const { startDate, endDate } = res[0];
                        const updatedRow = {
                          ...selectedRow,
                          startDate: moment(startDate),
                          endDate: moment(endDate),
                          classUuid: value.uuid,
                          classValue: value
                        };
                        handleRowChange(updatedRow);
                      })
                      .catch(console.debug);
                  } else {
                    const updatedRow = {
                      ...selectedRow,
                      classUuid: "",
                      classValue: { uuid: "", className: "" }
                    };
                    handleRowChange(updatedRow);
                  }
                }}
                requestConfig={
                  paymentRequest.contractType === "VIRTUAL_CONTRACT"
                    ? {
                        url: `v1/students/${paymentRequest.studentUuid}/learningClasses?contractType=VIRTUAL_CONTRACT`,
                        label: "className",
                        value: "uuid",
                        responseField: (res) => res
                      }
                    : {
                        url: `v1/classes?branchUuid=${branchInfo.value.uuid}`,
                        label: "className",
                        value: "uuid",
                        responseField: (res) => res.results.map(({ classDetails }) => classDetails),
                        onQueryChange: (value) => (value ? `&any=${value}` : "")
                      }
                }></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              disabled
              type="number"
              margin="dense"
              id="name"
              label={t("paymentContract.placeholder.registeredSessions")}
              value={selectedRow.registeredSessions || 0}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          {paymentRequest.contractType === "VIRTUAL_CONTRACT" && (
            <Grid item>
              <FormControl
                sx={{
                  paddingX: "0.5rem",
                  display: "inline-block",
                  flexDirection: "row",
                  alignItems: "center"
                }}>
                <FormLabel id="timeline-label" sx={{ paddingRight: "0.5rem" }}>
                  {t("paymentContract.placeholder.timeline")}
                </FormLabel>
                {potentialTimeline.map(({ creationDate, addedSessions }, i) => (
                  <FormControlLabel
                    key={i}
                    value={i}
                    checked={selectedTimelineIndexes.includes(i)}
                    control={<Checkbox />}
                    label={t("paymentContract.timeline.label", {
                      creationDate: moment(creationDate).format("DD/MM/YYYY"),
                      addedSessions
                    })}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      const newSelectedIndexes = e.target.checked
                        ? [...selectedTimelineIndexes, value]
                        : selectedTimelineIndexes.filter((x) => x !== value);
                      setSelectedTimelineIndexes(newSelectedIndexes);
                      if (newSelectedIndexes.length > 0) {
                        const earliestTimeLine = potentialTimeline
                          .filter((x, i) => newSelectedIndexes.includes(i))
                          .sort(
                            (a, b) =>
                              moment(a.startDate).format("YYYYMMDD") -
                              moment(b.startDate).format("YYYYMMDD")
                          )[0];
                        const { startDate, endDate } = earliestTimeLine;
                        const updatedRow = {
                          ...selectedRow,
                          startDate: moment(startDate),
                          endDate: moment(endDate)
                        };
                        handleRowChange(updatedRow);
                      }
                    }}
                  />
                ))}
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="start-date"
                format="DD/MM/YYYY"
                disabled={!selectedRow.classUuid}
                label={t("paymentContract.placeholder.startDate")}
                sx={{ width: "100%", paddingX: "0.5rem" }}
                value={selectedRow.startDate || moment()}
                onChange={(e) => {
                  if (e.isValid()) {
                    getWithoutLoading(`v1/classes/${selectedRow.classUuid}/potentialEndDate`, {
                      startDate: e,
                      sessionsCount: selectedRow.registeredSessions
                    })
                      .then((res) => {
                        const updatedRow = {
                          ...selectedRow,
                          startDate: e,
                          endDate: moment(res)
                        };
                        handleRowChange(updatedRow);
                      })
                      .catch(console.debug);
                  }
                }}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disabled
                id="end-date"
                format="DD/MM/YYYY"
                label={t("paymentContract.placeholder.endDate")}
                sx={{ width: "100%", paddingX: "0.5rem" }}
                value={selectedRow.endDate || moment()}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <TextField
              margin="dense"
              id="name"
              label={t("paymentContract.placeholder.description")}
              value={selectedRow.description}
              onChange={(e) => {
                const updatedRow = { ...selectedRow, description: e.target.value || "" };
                handleRowChange(updatedRow);
              }}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

export default PaymentContract;
