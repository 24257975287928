import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const Status = ({ size = "small", color = "success", status }) => {
  const { t } = useTranslation();

  return <Chip size={size} label={t(status)} color={color} sx={{ fontWeight: "bold" }} />;
};

export default Status;
