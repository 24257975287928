import { Box, useTheme } from "@mui/material";
import Header from "../../../components/header";
import { tokens } from "../../../theme";
import BadDebtDetails from "./BadDebtDetails";
import ComingDebDetails from "./ComingDebDetails";
import PaymentDebtDetails from "./PaymentDebtDetails";
import SessionsDebtDetails from "./SessionsDebtDetails";

const FeeReport = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Stars World Online Management" />
      </Box>
      <Box backgroundColor={colors.primary[400]} mb="0.5rem" p="0.5rem">
        <SessionsDebtDetails defaultExpanded={true} />
        <PaymentDebtDetails defaultExpanded={true} />
        <ComingDebDetails defaultExpanded={true} />
        <BadDebtDetails defaultExpanded={true} />
      </Box>
    </Box>
  );
};

export default FeeReport;
