import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";
import { branchInfo } from "../../../signals";

const DEFAULT_TEACHER_ID = "SW_GV_";

const INITIAL_DATA = {
  username: (DEFAULT_TEACHER_ID + new Date().getTime()).substring(0, 25),
  password: "",
  firstName: "",
  lastName: "",
  displayName: "",
  email: "",
  birthday: null,
  mobile: "",
  status: "ACTIVE"
};

const TeacherDetailsDialog = ({ data, onConfirm, onClose, ...props }) => {
  const { t } = useTranslation();

  const [teacherDetails, setTeacherDetails] = useState(INITIAL_DATA);

  const updateFieldValue = (fieldName, fieldValue) => {
    setTeacherDetails({ ...teacherDetails, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if (data) {
      setTeacherDetails({
        ...data,
        birthday: data.birthday ? moment(new Date(data.birthday)) : null,
        branchUuid: branchInfo.value.uuid,
        branchName: branchInfo.value.branchName
      });
    } else {
      setTeacherDetails({
        ...INITIAL_DATA,
        username: (branchInfo.value.branchId + "_GV_" + new Date().getTime()).substring(0, 25)
      });
    }
  }, [data]);

  const confirmSaveTeacher = () => {
    const formData = {
      ...teacherDetails,
      branchUuid: branchInfo.value.uuid,
      branchName: branchInfo.value.branchName
    };
    onConfirm(formData);
    setTeacherDetails({
      ...INITIAL_DATA,
      username: (branchInfo.value.branchId + "_GV_" + new Date().getTime()).substring(0, 25)
    });
  };

  return (
    <Dialog
      {...props}
      onConfirm={confirmSaveTeacher}
      onClose={() => {
        onClose();
        setTeacherDetails({
          ...INITIAL_DATA,
          username: (branchInfo.value.branchId + "_GV_" + new Date().getTime()).substring(0, 25)
        });
      }}
      width="1000px">
      <Grid
        container
        p="1rem"
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                onChange={(e, value) => {
                  setTeacherDetails({
                    ...teacherDetails,
                    branchUuid: branchInfo.value.uuid || "",
                    branchName: branchInfo.value.branchName || ""
                  });
                }}
                disabled
                value={{
                  uuid: branchInfo.value.uuid,
                  branchName: branchInfo.value.branchName
                }}
                placeholder="teacherDetails.placeholder.branchName"
                requestConfig={{
                  url: "v1/admin/branches",
                  label: "branchName",
                  value: "uuid"
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="status-label" color="neutral">
                {t("teacherDetails.placeholder.status")}
              </InputLabel>
              <Select
                labelId="status-label"
                id="status"
                label={t("teacherDetails.placeholder.status")}
                MenuProps={{
                  style: { zIndex: 15003 }
                }}
                disabled={!data}
                value={teacherDetails.status}
                onChange={(e) => updateFieldValue("status", e.target.value)}
                sx={{ width: "100%" }}>
                <MenuItem value="ACTIVE">{t("teachers.status.ACTIVE.label")}</MenuItem>
                <MenuItem value="LOCKED">{t("teachers.status.LOCKED.label")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("teacherDetails.placeholder.firstName")}
              value={teacherDetails.firstName || ""}
              onChange={(e) => updateFieldValue("firstName", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("teacherDetails.placeholder.lastName")}
              value={teacherDetails.lastName || ""}
              onChange={(e) => updateFieldValue("lastName", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("teacherDetails.placeholder.displayName")}
              value={teacherDetails.displayName || ""}
              onChange={(e) => updateFieldValue("displayName", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("teacherDetails.placeholder.mobile")}
              value={teacherDetails.mobile || ""}
              onChange={(e) => updateFieldValue("mobile", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("teacherDetails.placeholder.email")}
              value={teacherDetails.email || ""}
              onChange={(e) => updateFieldValue("email", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="birthday"
                format="DD/MM/YYYY"
                label={t("teacherDetails.placeholder.birthday")}
                sx={{ width: "100%", paddingX: "0.5rem" }}
                value={teacherDetails.birthday}
                onChange={(e) => updateFieldValue("birthday", e)}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item container alignItems="center" display="none">
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("teacherDetails.placeholder.username")}
              value={teacherDetails.username || ""}
              onChange={(e) => updateFieldValue("username", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("teacherDetails.placeholder.password")}
              value={teacherDetails.password || ""}
              onChange={(e) => updateFieldValue("password", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TeacherDetailsDialog;
