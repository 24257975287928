import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const Parent = ({ parentName, phoneNumber }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400], marginY: "0.5rem" }}>
        {parentName}
      </Typography>
      {phoneNumber && phoneNumber !== "-" && (
        <Typography sx={{ display: "flex", alignItems: "center" }}>
          <LocalPhoneIcon sx={{ marginRight: "0.5rem" }} />
          {0 + phoneNumber}
        </Typography>
      )}
    </Box>
  );
};

export default Parent;
