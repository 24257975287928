import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventNoteIcon from "@mui/icons-material/EventNote";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Parent from "../../../components/cell/Parent";
import Header from "../../../components/header";
import ProgressCircle from "../../../components/progress/ProgressCircle";
import Status from "../../../components/status";
import Table from "../../../components/table";
import { get, post } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import { getDisplayName } from "../../../utils/UserUtil";
import MakeupClassDialog from "../makeup-class/MakeupClassDialog";
import CheckinList from "./CheckinListDialog";

const DEFAULT_SEARCH_CRITERIA = {
  scheduleLabel: "",
  classSearchType: "TODAY",
  classUuid: "",
  classScheduleUuid: ""
};

const DEFAULT_WEEKS = 50;

const getScheduleOptions = (schedule, classSearchType, holidays) => {
  const scheduleOptions = schedule
    .map((s) => ({
      ...s,
      day: moment(s.scheduleDate)
    }))
    .filter(({ day }) => !holidays.some((holiday) => day.isSame(holiday, "day")));
  return classSearchType === "ALL"
    ? scheduleOptions
    : scheduleOptions.filter(({ isPastDay, day }) => {
        if (classSearchType === "TODAY") {
          return day.isSame(moment(), "day");
        } else if (classSearchType === "YESTERDAY") {
          return day.isSame(moment().subtract(1, "days"), "day");
        } else {
          return isPastDay;
        }
      });
};

const Attendance = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);
  const [checkinList, setCheckinList] = useState([]);
  const [classSchedule, setClassSchedule] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);
  const [total, setTotal] = useState(0);
  const [holidays, setHolidays] = useState([]);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 30,
    order: "asc",
    orderBy: "scheduleDate"
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [pagination]);

  useEffect(() => {
    setPagination({
      pageNumber: 0,
      pageSize: 30,
      order: "asc",
      orderBy: "scheduleDate"
    });
  }, [searchCriteria, branchInfo.value]);

  const refreshData = () => {
    const { scheduleLabel, ...criteria } = searchCriteria;
    get("v1/classes/histories", {
      ...pagination,
      ...criteria,
      branchUuid: branchInfo.value.uuid
    })
      .then((res) => {
        const { pageSize, pageNumber } = pagination;
        const histories = res.results.map((x, index) => ({
          id: pageNumber * pageSize + index + 1,
          ...x,
          hideCheckbox: x.historyStatus === "ABORTED"
        }));
        setTotal(res.total);
        setData(histories);
      })
      .catch(console.debug);

    const start = moment().subtract(DEFAULT_WEEKS, "weeks");
    const end = moment().add(1, "weeks");
    get("v1/admin/holidays/byRange", { from: start.valueOf(), to: end.valueOf() })
      .then((res) => {
        const dates = [];
        res
          .filter(({ status }) => status === "ACTIVE")
          .forEach(({ startDate, endDate }) => {
            const currDate = moment(startDate).startOf("day");
            const lastDate = moment(endDate).startOf("day");
            while (currDate.add(1, "days").isSameOrBefore(lastDate)) {
              dates.push(currDate.clone().toDate());
            }
          });
        setHolidays(dates);
      })
      .catch(console.debug);
  };

  const columns = [
    { field: "id", label: "attendance.table.field.id.label" },
    {
      field: "studentName",
      label: "attendance.table.field.studentName.label"
    },
    {
      field: "parent.username",
      label: "attendance.table.field.parentName.label",
      valueGetter: ({ parent }) => (
        <Parent parentName={getDisplayName(parent)} phoneNumber={parent?.mobile} />
      )
    },
    {
      field: "historyStatus",
      label: "attendance.table.field.status.label",
      align: "center",
      component: (rowData) => (
        <Status
          status={t(`attendance.historyStatus.${rowData.historyStatus}`)}
          color={getStatusColor(rowData.historyStatus)}
        />
      )
    },
    {
      field: "description",
      label: "attendance.table.field.note.label",
      sortable: false
    },
    {
      field: "scheduleDate",
      label: "attendance.table.field.time.label",
      valueGetter: ({ scheduleDate }) => moment(scheduleDate).format("DD/MM/YYYY")
    }
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "secondary";
      case "MAKE_UP_UNCOMPLETED":
        return "warning";
      case "CHECKED_IN":
        return "success";
      case "ABSENCE":
        return "error";
      case "MAKE_UP_COMPLETED":
        return "info";
      case "LEAVE_OF_ABSENCE":
      case "ABORTED":
      default:
        return "primary";
    }
  };

  const handleClose = () => {
    setSelectedRow(null);
    setCheckinList([]);
  };

  const handleCheckin = ({ checkedInList, absenceList, loaList }) => {
    Promise.all([
      post("v1/students/attendance", {
        attendanceUpdates: checkedInList.map(({ uuid, note }) => ({
          historyUuid: uuid,
          note
        })),
        status: "CHECKED_IN"
      }),
      post("v1/students/attendance", {
        attendanceUpdates: absenceList.map(({ uuid, note }) => ({
          historyUuid: uuid,
          note
        })),
        status: "ABSENCE"
      }),
      post("v1/students/attendance", {
        attendanceUpdates: loaList.map(({ uuid, note }) => ({
          historyUuid: uuid,
          note
        })),
        status: "LEAVE_OF_ABSENCE"
      })
    ])
      .then(() => {
        refreshData();
        handleClose();
      })
      .catch(console.debug);
  };

  const handleCriteriaChange = (field, value) => {
    setSearchCriteria({ ...searchCriteria, [field]: value || "" });
  };

  const compareDays = (a, b) => {
    if (a.day.isAfter(b.day)) {
      return -1;
    }
    return 1;
  };

  const getCount = (status) => {
    const total = data?.length || 0;
    const count = data.filter(({ historyStatus }) => status === historyStatus)?.length || 0;
    return { total, count, percentage: count / (total || 1) };
  };

  const handleConfirmMakeUp = (data) => {
    const { uuid, teacherUuid, scheduleDate } = data;
    post(`v1/classes/histories/${uuid}/makeup`, {
      teacherUuid,
      scheduleDate: scheduleDate.toDate()
    })
      .then(() => {
        refreshData();
        handleClose();
      })
      .catch(console.debug);
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Stars World Online Management" />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        alignItems="center">
        <Grid item xs={4}>
          <FormControl sx={{ minWidth: 150 }} size="small">
            <InputLabel id="class-status-label" color="neutral">
              {t("attendance.placeholder.classSearchType")}
            </InputLabel>
            <Select
              labelId="class-status-label"
              id="class-status"
              label={t("attendance.placeholder.classSearchType")}
              value={searchCriteria.classSearchType}
              onChange={(e) => handleCriteriaChange("classSearchType", e.target.value)}>
              <MenuItem value="ALL">{t("attendance.classSearchType.ALL")}</MenuItem>
              <MenuItem value="TODAY">{t("attendance.classSearchType.TODAY")}</MenuItem>
              <MenuItem value="YESTERDAY">{t("attendance.classSearchType.YESTERDAY")}</MenuItem>
              <MenuItem value="COMPLETED">{t("attendance.classSearchType.COMPLETED")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ minWidth: 250, width: "100%", padding: "0.5rem" }} size="small">
            <Autocomplete
              placeholder="attendance.placeholder.class"
              onChange={(e, value) => {
                get(
                  `v1/classes/${value?.uuid}/scheduleWeeks?limit=${DEFAULT_WEEKS}&searchType=${searchCriteria.classSearchType}`
                )
                  .then((res) => {
                    setClassSchedule(res);
                    setSearchCriteria({
                      ...searchCriteria,
                      classUuid: value?.uuid,
                      scheduleLabel: "",
                      classScheduleUuid: "",
                      scheduleDate: undefined
                    });
                  })
                  .catch(console.debug);
              }}
              requestConfig={{
                url: `v1/classes?branchUuid=${branchInfo.value.uuid}`,
                label: "className",
                value: "uuid",
                responseField: (res) => res.results.map(({ classDetails }) => classDetails),
                onQueryChange: (value) => (value ? `&any=${value}` : "")
              }}></Autocomplete>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ minWidth: 400 }} size="small">
            <InputLabel id="class-status-label" color="neutral">
              {t("attendance.placeholder.schedule")}
            </InputLabel>
            <Select
              labelId="class-status-label"
              id="class-status"
              label={t("attendance.placeholder.schedule")}
              value={searchCriteria.scheduleLabel}
              SelectDisplayProps={{
                sx: {
                  display: "flex !important",
                  alignItems: "center !important"
                }
              }}
              onChange={(e) => {
                const schedule = e.target.value;
                if (schedule) {
                  const { scheduleUuid, day } = JSON.parse(schedule);
                  setSearchCriteria({
                    ...searchCriteria,
                    scheduleLabel: schedule,
                    classScheduleUuid: scheduleUuid,
                    scheduleDate: moment(day)
                  });
                }
              }}>
              <MenuItem sx={{ display: "none" }} value=""></MenuItem>
              {getScheduleOptions(classSchedule, searchCriteria.classSearchType, holidays)
                .sort(compareDays)
                .map((schedule, index) => (
                  <MenuItem
                    key={index}
                    value={JSON.stringify(schedule)}
                    sx={{ display: "flex", alignItems: "center" }}>
                    {t("attendance.scheduleOptions.label", {
                      teacherName: schedule.teacherName,
                      weekDay: t(
                        "timetable.weekDay." +
                          schedule.dayOfWeek.substring(0, 3).toLowerCase() +
                          ".label"
                      ),
                      scheduleDate: schedule.day.format("DD/MM/YYYY"),
                      timeSlot: schedule.timeSlot
                    })}
                    &nbsp;
                    {schedule.fullyCheckedIn ? (
                      <EventAvailableIcon color="success" />
                    ) : schedule.notCheckedIn ? (
                      <EventBusyIcon />
                    ) : (
                      <EventNoteIcon color="warning" />
                    )}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#0e8416" fontWeight="bold">
            {t("attendance.checkinReport.onTime.label", {
              count: getCount("CHECKED_IN").count,
              total: getCount("CHECKED_IN").total
            })}
          </Typography>
          <ProgressCircle
            progress={getCount("CHECKED_IN").percentage}
            circleColor="#dbd7d7"
            textColor="#0e8416"
            progressingColor="green"></ProgressCircle>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#d32f2f" fontWeight="bold">
            {t("attendance.checkinReport.absence.label", {
              count: getCount("ABSENCE").count,
              total: getCount("ABSENCE").total
            })}
          </Typography>
          <ProgressCircle
            progress={getCount("ABSENCE").percentage}
            circleColor="#dbd7d7"
            textColor="#d32f2f"
            progressingColor="#d32f2f"></ProgressCircle>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#06389d" fontWeight="bold">
            {t("attendance.checkinReport.makeupCompleted.label", {
              count: getCount("MAKE_UP_COMPLETED").count,
              total: getCount("MAKE_UP_COMPLETED").total
            })}
          </Typography>
          <ProgressCircle
            progress={getCount("MAKE_UP_COMPLETED").percentage}
            circleColor="#dbd7d7"
            textColor="#06389d"
            progressingColor="#0288d1"></ProgressCircle>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#ed6c02" fontWeight="bold">
            {t("attendance.checkinReport.makeupUnCompleted.label", {
              count: getCount("MAKE_UP_UNCOMPLETED").count,
              total: getCount("MAKE_UP_UNCOMPLETED").total
            })}
          </Typography>
          <ProgressCircle
            progress={getCount("MAKE_UP_UNCOMPLETED").percentage}
            circleColor="#dbd7d7"
            textColor="#ed6c02"
            progressingColor="#ed6c02"></ProgressCircle>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography pr="0.5rem" color="#101010" fontWeight="bold">
            {t("attendance.checkinReport.loa.label", {
              count: getCount("LEAVE_OF_ABSENCE").count,
              total: getCount("LEAVE_OF_ABSENCE").total
            })}
          </Typography>
          <ProgressCircle
            progress={getCount("LEAVE_OF_ABSENCE").percentage}
            circleColor="#dbd7d7"
            textColor="#101010"
            progressingColor="#3e3e41"></ProgressCircle>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table
          checkboxSelection
          checkBoxSelectLabel="attendance.table.button.checkin.label"
          columnConfig={columns}
          confirmSelections={setCheckinList}
          data={data}
          totalResults={total}
          serverPaging={true}
          defaultPagination={pagination}
          paginationChanged={(paging) => setPagination(paging)}
        />
      </Box>
      <MakeupClassDialog
        open={!!selectedRow}
        handleClose={handleClose}
        data={selectedRow}
        confirmMakeUpClass={handleConfirmMakeUp}
      />
      <CheckinList
        checkinList={checkinList}
        closeCheckinDialog={handleClose}
        confirmCheckinDialog={handleCheckin}
      />
    </Box>
  );
};

export default Attendance;
