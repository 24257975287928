import { Box, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { branchInfo, branches } from "../../signals";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../utils/CookieUtil";
import CookieConstants from "../../constants/Cookies";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box mb="0.5rem">
      <Typography variant="h2" color={colors.grey[100]} fontWeight="bold" sx={{ m: "0 0 5px 0" }}>
        {title}
      </Typography>
      <Typography
        variant="div"
        color={colors.greenAccent[400]}
        sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" width="60px">
          {t("header.subtitle.label")}
        </Typography>
        {branches.value.length > 0 ? (
          <Select
            MenuProps={{
              style: { zIndex: 15003 }
            }}
            value={branchInfo.value}
            onChange={(e) => {
              const { uuid } = e.target.value;
              setCookie(CookieConstants.CURRENT_BRANCH_UUID, uuid);
              navigate(0);
            }}
            SelectDisplayProps={{
              sx: {
                padding: "0.5rem !important",
                paddingRight: "2rem !important"
              }
            }}
            sx={{
              minWidth: "150px",
              color: colors.greenAccent[400],
              fontWeight: "bold",
              fontSize: "16px"
            }}>
            {branches.value.map((branchInfo, index) => (
              <MenuItem key={index} value={branchInfo}>
                {branchInfo.branchName}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography variant="h4" color={colors.greenAccent[400]}>
            <b>{branchInfo.value.branchName || ""}</b>
          </Typography>
        )}
      </Typography>
    </Box>
  );
};

export default Header;
