import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Status from "../../../components/status";
import Table from "../../../components/table";
import { get } from "../../../services/HttpClient";
import { renewInvoice } from "../../../signals";

const StudentInvoices = ({ studentId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [invoiceDetails, setInvoiceDetails] = useState([]);

  useEffect(() => {
    if (studentId) {
      refreshData();
    }
  }, [studentId]);

  const refreshData = () => {
    get(`v1/students/${studentId}/invoices`)
      .then((res) => {
        setInvoiceDetails(
          res.reduce((results, x) => {
            if (x.debtSessions) {
              return [
                ...results,
                x,
                {
                  ...x,
                  invoiceId: t("studentInvoices.table.field.inDebt.label") + x.className,
                  status: "IN_DEBT",
                  totalAmount: x.debtAmount,
                  registeredSessions: x.debtSessions
                }
              ];
            }
            return [...results, x];
          }, [])
        );
      })
      .catch(console.debug);
  };

  const columns = [
    {
      field: "invoiceId",
      label: "studentInvoices.table.field.invoiceId.label",
      sortable: false
    },
    {
      field: "status",
      label: "studentInvoices.table.field.status.label",
      sortable: false,
      component: ({ status }) => (
        <Status status={`studentInvoices.status.${status}`} color={getStatusColor(status)} />
      )
    },
    {
      field: "totalAmount",
      label: "studentInvoices.table.field.totalAmount.label",
      sortable: false,
      valueGetter: ({ totalAmount }) =>
        totalAmount?.toLocaleString("en-US", {
          style: "currency",
          currency: "VND",
          currencyDisplay: "symbol"
        })
    },
    {
      field: "courseName",
      label: "studentInvoices.table.field.packageName.label",
      sortable: false,
      valueGetter: ({ status, courseName }) => (status === "IN_DEBT" ? "" : courseName)
    },
    {
      field: "singlePrice",
      label: "studentInvoices.table.field.singlePrice.label",
      sortable: false,
      valueGetter: ({ status, singlePrice }) =>
        status === "IN_DEBT"
          ? ""
          : singlePrice?.toLocaleString("en-US", {
              style: "currency",
              currency: "VND",
              currencyDisplay: "symbol"
            })
    },
    {
      field: "registeredSessions",
      label: "studentInvoices.table.field.registeredSessions.label",
      sortable: false
    },
    {
      field: "startDate",
      label: "studentInvoices.table.field.startDate.label",
      sortable: false,
      valueGetter: ({ status, startDate }) =>
        status === "IN_DEBT" ? "" : new moment(startDate).format("DD/MM/YYYY")
    },
    {
      field: "endDate",
      label: "studentInvoices.table.field.endDate.label",
      sortable: false,
      valueGetter: ({ status, endDate }) =>
        status === "IN_DEBT" ? "" : new moment(endDate).format("DD/MM/YYYY")
    },
    {
      field: "completedDate",
      label: "studentInvoices.table.field.completedDate.label",
      sortable: false,
      valueGetter: ({ status, completedDate }) =>
        status === "IN_DEBT" ? "" : new moment(completedDate).format("DD/MM/YYYY")
    }
  ];
  const rowActions = [
    {
      icon: <CurrencyExchangeIcon />,
      tooltip: t("studentInvoices.table.action.pay.label"),
      action: (rowData) => {
        const { packageValue } = rowData;
        renewInvoice.value = {
          ...rowData,
          registeredSessions: packageValue?.quantity || 0,
          isSettlement: false
        };
        navigate("../../../finance/payment-contract/new");
      },
      showIf: ({ status }) => status === "IN_DEBT"
    },
    {
      icon: <PriceCheckIcon />,
      tooltip: t("studentInvoices.table.action.payInvoice.label"),
      action: (rowData) => {
        navigate(`../../../finance/payment-contract/${rowData.uuid}`);
      },
      showIf: ({ status }) => ["ACTIVE", "IN_PROGRESS"].includes(status)
    }
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "COMPLETED":
        return "success";
      case "IN_PROGRESS":
        return "warning";
      case "ACTIVE":
      default:
        return "info";
    }
  };

  return (
    <Table
      columnConfig={columns}
      showHeaderToolbar={false}
      showFooterToolbar={false}
      data={invoiceDetails}
      rowActions={rowActions}
    />
  );
};

export default StudentInvoices;
