import { FormControl, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";
import { branchInfo } from "../../../signals";

const INITIAL_DATA = {
  classUuid: "",
  classValue: {
    uuid: "",
    className: ""
  },
  startDate: moment(),
  sessions: "",
  note: ""
};

const AddStudentToClassDialog = ({ onConfirm, onClose, data, ...props }) => {
  const { t } = useTranslation();

  const [addStudentDetails, setAddStudentDetails] = useState(INITIAL_DATA);

  useEffect(() => {
    if (data) {
      setAddStudentDetails({ ...INITIAL_DATA, ...data });
    }
  }, [data]);

  const updateFieldValue = (fieldName, fieldValue) => {
    setAddStudentDetails({ ...addStudentDetails, [fieldName]: fieldValue });
  };

  const confirmAddStudent = () => {
    onConfirm(addStudentDetails);
  };

  const cancelAddStudent = () => {
    setAddStudentDetails(INITIAL_DATA);
    onClose();
  };

  return (
    <Dialog {...props} onConfirm={confirmAddStudent} onClose={cancelAddStudent}>
      <Grid
        container
        p="1rem"
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <Autocomplete
              value={addStudentDetails.classValue}
              placeholder="loadStudents.placeholder.className"
              onChange={(e, value) => {
                setAddStudentDetails({
                  ...addStudentDetails,
                  classUuid: value?.uuid,
                  classValue: value || {
                    className: "",
                    uuid: ""
                  }
                });
              }}
              requestConfig={{
                url: `v1/classes?branchUuid=${branchInfo.value.uuid}`,
                label: "className",
                value: "uuid",
                responseField: (res) => res.results.map(({ classDetails }) => classDetails),
                onQueryChange: (value) => (value ? `&any=${value}` : "")
              }}></Autocomplete>
          </FormControl>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="start-date"
              label={t("loadStudents.placeholder.startDate")}
              format="DD/MM/YYYY"
              sx={{ width: "100%", paddingX: "0.5rem" }}
              value={addStudentDetails.startDate}
              onChange={(e) => updateFieldValue("startDate", e)}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <TextField
            type="number"
            margin="dense"
            id="name"
            disabled
            label={t("loadStudents.placeholder.sessions")}
            value={addStudentDetails.sessions}
            onChange={(e) => updateFieldValue("sessions", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem" }}
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("loadStudents.placeholder.note")}
            value={addStudentDetails.note}
            onChange={(e) => updateFieldValue("note", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem" }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AddStudentToClassDialog;
