import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";
import { SCHEDULE_TIMES } from "../../../constants/ScheduleTime";
import { branchInfo } from "../../../signals";

const INITIAL_DATA = {
  className: "",
  startDate: moment(),
  schedule: [],
  status: "ACTIVE"
};

// // TODO handle requried fields
// const REQUIRED_FIELDS = ["branchUuid", "className", "startDate", "status"];

const TIMESLOT_DELIMITER = "/";

const ClassDetailsDialog = ({ data, editable, onConfirm, ...props }) => {
  const { t } = useTranslation();

  const [classDetails, setClassDetails] = useState(INITIAL_DATA);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    if (editable) {
      setClassDetails({
        ...data,
        branchUuid: branchInfo.value.uuid,
        branchName: branchInfo.value.branchName,
        status: data.classStatus,
        startDate: moment(new Date(data?.startDate))
      });
      const scheduleList = data.schedule.reduce((results, x) => {
        const weekDay = getWeekDay(x.dayOfWeek);
        const existedIndex = results.findIndex(
          ({ dayOfWeek, timeSlot, classroomUuid }) =>
            Number(weekDay) === Number(getWeekDay(dayOfWeek)) &&
            x.timeSlot === timeSlot &&
            x.classroomUuid === classroomUuid
        );
        if (existedIndex > -1) {
          const existedSchedule = results[existedIndex];
          results.splice(existedIndex, 1, {
            ...existedSchedule,
            dayOfWeek: weekDay,
            startDate: x.startDate
              ? moment(new Date(x.startDate))
              : moment(new Date(data?.startDate)),
            teachers: [
              ...(existedSchedule.teachers || []),
              { uuid: x.teacherUuid, niceName: x.teacherName }
            ]
          });
          return [...results];
        } else {
          return [
            ...results,
            {
              dayOfWeek: weekDay,
              timeSlot: x.timeSlot,
              startDate: x.startDate
                ? moment(new Date(x.startDate))
                : moment(new Date(data?.startDate)),
              classroomUuid: x.classroomUuid,
              classroomType: x.classroomType,
              classroomName: x.classroomName,
              rawValue: weekDay + TIMESLOT_DELIMITER + x.timeSlot,
              teachers: [{ uuid: x.teacherUuid, niceName: x.teacherName }]
            }
          ];
        }
      }, []);
      setSchedule(scheduleList);
    } else {
      setClassDetails(INITIAL_DATA);
      setSchedule([]);
    }
  }, [data, editable]);

  const getWeekDay = (dayOfWeek) => {
    switch (dayOfWeek) {
      case "MONDAY":
        return 1;
      case "TUESDAY":
        return 2;
      case "WEDNESDAY":
        return 3;
      case "THURSDAY":
        return 4;
      case "FRIDAY":
        return 5;
      case "SATURDAY":
        return 6;
      case "SUNDAY":
        return 7;
      default:
        return dayOfWeek;
    }
  };

  const updateFieldValue = (fieldName, fieldValue) => {
    setClassDetails({ ...classDetails, [fieldName]: fieldValue });
  };

  const confirmSaveClass = () => {
    const formData = {
      ...classDetails,
      branchUuid: branchInfo.value.uuid,
      branchName: branchInfo.value.branchName,
      schedule: schedule.reduce((results, x) => {
        const data = x.teachers.map(({ uuid }) => ({
          ...x,
          dayOfWeek: Number(x.dayOfWeek) - 1,
          teacherUuid: uuid
        }));
        return [...results, ...data];
      }, [])
    };
    onConfirm(!editable, formData);
  };

  const isTimeSlotSelected = (time, weekDay) =>
    schedule.some(
      ({ timeSlot, dayOfWeek, rawIndex }) =>
        timeSlot === time && Number(dayOfWeek) === Number(weekDay)
    );

  return (
    <Dialog {...props} onConfirm={confirmSaveClass} width="1000px">
      <Grid
        container
        p="1rem"
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <Autocomplete
              onChange={(e, value) => {
                setClassDetails({
                  ...classDetails,
                  branchUuid: branchInfo.value.uuid || "",
                  branchName: branchInfo.value.branchName || ""
                });
              }}
              disabled
              value={{
                uuid: branchInfo.value.uuid,
                branchName: branchInfo.value.branchName
              }}
              placeholder="classDetails.placeholder.branchName"
              requestConfig={{
                url: "v1/admin/branches",
                label: "branchName",
                value: "uuid"
              }}></Autocomplete>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("classDetails.placeholder.className")}
            value={classDetails.className}
            onChange={(e) => updateFieldValue("className", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem" }}
          />
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="start-date"
              format="DD/MM/YYYY"
              label={t("classDetails.placeholder.startDate")}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              value={classDetails.startDate}
              onChange={(e) => updateFieldValue("startDate", e)}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <Autocomplete
              placeholder="classDetails.placeholder.courseName"
              onChange={(e, value) => {
                setClassDetails({
                  ...classDetails,
                  courseUuid: value.uuid || "",
                  courseName: value.courseName || ""
                });
              }}
              value={{
                uuid: classDetails.courseUuid,
                courseName: classDetails.courseName
              }}
              requestConfig={{
                url: `v1/admin/courses?branchUuid=${branchInfo.value.uuid}`,
                label: "courseName",
                value: "uuid"
              }}></Autocomplete>
          </FormControl>
        </Grid>
        <Grid item sx={{ paddingX: "0.5rem" }}>
          <Typography component="label">{t("classDetails.placeholder.schedule")}</Typography>{" "}
          <IconButton color="success" onClick={() => setSchedule([...schedule, {}])}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Grid>
        {schedule.map((scheduleItem, index) => (
          <Grid
            key={index}
            item
            sx={{
              display: "flex",
              alignItems: "center",
              paddingTop: "5px !important",
              width: "100%"
            }}>
            <FormControl
              sx={{ width: "100%", maxWidth: "200px", marginY: "0.5rem", paddingX: "0.5rem" }}
              size="small">
              <InputLabel id="class-status-label" color="neutral">
                {t("classDetails.placeholder.schedule")}
              </InputLabel>
              <Select
                labelId="class-status-label"
                id="class-status"
                label={t("classDetails.placeholder.schedule")}
                MenuProps={{
                  style: { zIndex: 15003 }
                }}
                value={scheduleItem.rawValue || ""}
                onChange={(e) => {
                  const [dayOfWeek, timeSlot] = e.target.value.split(TIMESLOT_DELIMITER);
                  const scheduleList = [...schedule];
                  scheduleList.splice(index, 1, {
                    ...scheduleItem,
                    dayOfWeek,
                    timeSlot,
                    rawValue: e.target.value
                  });
                  setSchedule(scheduleList);
                }}
                sx={{ width: "100%" }}>
                {SCHEDULE_TIMES.reduce((items, x) => {
                  items.push(
                    <ListSubheader key={x.weekDayLabel}>
                      <Typography component="label" fontWeight="bold" fontSize="16px">
                        {x.weekDayLabel}
                      </Typography>
                    </ListSubheader>
                  );
                  items.push(
                    x.timeSlots.map((time, index) => (
                      <MenuItem
                        disabled={isTimeSlotSelected(time, x.weekDay)}
                        key={x.weekDay + time}
                        value={x.weekDay + TIMESLOT_DELIMITER + time}>
                        {time}&nbsp;{x.weekDayLabel}
                      </MenuItem>
                    ))
                  );
                  return items;
                }, [])}
              </Select>
            </FormControl>
            <FormControl
              sx={{ width: "100%", maxWidth: "200px", marginY: "0.5rem", paddingX: "0.5rem" }}
              size="small">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  id="schedule-start-date"
                  format="DD/MM/YYYY"
                  minDate={classDetails.startDate}
                  label={t("classDetails.placeholder.startDate")}
                  sx={{ width: "100%", paddingX: "0.5rem" }}
                  value={scheduleItem.startDate || classDetails.startDate}
                  onChange={(e) => {
                    const scheduleList = [...schedule];
                    scheduleList.splice(index, 1, {
                      ...scheduleItem,
                      startDate: e || classDetails.startDate
                    });
                    setSchedule(scheduleList);
                  }}
                  slotProps={{
                    textField: { size: "small" },
                    popper: {
                      style: {
                        zIndex: 15003
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ width: "100%", maxWidth: "200px", padding: "0.5rem" }} size="small">
              <Autocomplete
                value={{
                  uuid: scheduleItem.classroomUuid,
                  classroomName: scheduleItem.classroomName
                }}
                placeholder="classDetails.placeholder.classroomName"
                onChange={(e, value) => {
                  if (value) {
                    const { uuid, classroomType, classroomName } = value;
                    const scheduleList = [...schedule];
                    scheduleList.splice(index, 1, {
                      ...scheduleItem,
                      classroomUuid: uuid,
                      classroomType,
                      classroomName
                    });
                    setSchedule(scheduleList);
                  }
                }}
                requestConfig={{
                  url: `v1/admin/classrooms?branchUuid=${branchInfo.value.uuid}&status=ACTIVE`,
                  label: "classroomName",
                  value: "uuid"
                }}></Autocomplete>
            </FormControl>
            <FormControl sx={{ width: "100%", padding: "0.5rem" }} size="small">
              <Autocomplete
                multiple
                value={scheduleItem.teachers || []}
                placeholder="classDetails.placeholder.teacherName"
                onChange={(e, value) => {
                  const scheduleList = [...schedule];
                  scheduleList.splice(index, 1, {
                    ...scheduleItem,
                    teachers:
                      value?.length > 0
                        ? value.map(({ uuid, niceName }) => ({
                            uuid,
                            niceName
                          }))
                        : []
                  });
                  setSchedule(scheduleList);
                }}
                requestConfig={{
                  url: `v1/teachers?status=ACTIVE&branchUuid=${branchInfo.value.uuid}&orderBy=displayName&order=asc`,
                  label: "niceName",
                  value: "uuid",
                  responseField: "results"
                }}></Autocomplete>
            </FormControl>
            <IconButton
              color="error"
              onClick={() => {
                const scheduleList = [...schedule];
                scheduleList.splice(index, 1);
                setSchedule(scheduleList);
              }}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        ))}
        <Grid item>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <InputLabel id="class-status-label" color="neutral">
              {t("classDetails.placeholder.status")}
            </InputLabel>
            <Select
              labelId="class-status-label"
              id="class-status"
              label={t("classDetails.placeholder.status")}
              MenuProps={{
                style: { zIndex: 15003 }
              }}
              value={classDetails.status}
              onChange={(e) => updateFieldValue("status", e.target.value)}
              sx={{ width: "100%" }}>
              <MenuItem value="ACTIVE">Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ClassDetailsDialog;
