import styled from "@emotion/styled";

const StyleWrapper = styled.div`
  .fc .fc-timegrid-slot {
    height: 100px !important;
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid #398754;
  }
  .fc-theme-standard th {
    border-bottom: none !important;
  }
  .fc .fc-timegrid-col-bg:hover {
    background-color: #e0e0e0;
  }
  .fc .fc-today-button.fc-button.fc-button-primary {
    background-color: var(--fc-today-bg-color);
    color: rgba(0, 0, 0, 0.87);
  }
`;

export default StyleWrapper;
