import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";

const INITIAL_DATA = {
  holidayName: "",
  holidayFrom: "",
  holidayTo: "",
  description: "",
  status: "",
  excludedClasses: "",
  notification: ""
};

const AddHolidayDialog = ({ onConfirm, ...props }) => {
  const { t } = useTranslation();

  const [addHoliday, setAddHoliday] = useState(INITIAL_DATA);
  const [excludedClasses, setExcludedClasses] = useState([]);

  useEffect(() => {
    setAddHoliday(INITIAL_DATA);
  }, []);

  const updateFieldValue = (fieldName, fieldValue) => {
    setAddHoliday({ ...addHoliday, [fieldName]: fieldValue });
  };

  const confirmSaveClass = () => {
    setAddHoliday(INITIAL_DATA);
    onConfirm({ ...addHoliday, excludedClasses });
  };

  return (
    <Dialog {...props} onConfirm={confirmSaveClass}>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("addHoliday.placeholder.holidayName")}
            value={addHoliday.holidayName}
            onChange={(e) => updateFieldValue("holidayName", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem", margin: 0 }}
          />
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="start-date"
              format="DD/MM/YYYY"
              label={t("addHoliday.placeholder.holidayFrom")}
              onChange={(e) => updateFieldValue("holidayFrom", e)}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="end-date"
              format="DD/MM/YYYY"
              label={t("addHoliday.placeholder.holidayTo")}
              onChange={(e) => updateFieldValue("holidayTo", e)}
              sx={{ width: "100%", paddingX: "0.5rem" }}
              slotProps={{
                textField: { size: "small" },
                popper: {
                  style: {
                    zIndex: 15003
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="name"
            label={t("addHoliday.placeholder.description")}
            value={addHoliday.description}
            onChange={(e) => updateFieldValue("description", e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            sx={{ paddingX: "0.5rem", margin: 0 }}
          />
        </Grid>
        <Grid item>
          <FormControl
            sx={{
              paddingX: "0.5rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}>
            <FormLabel id="status-label" sx={{ paddingRight: "0.5rem" }}>
              {t("addHoliday.placeholder.status")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="status-label"
              name="row-radio-buttons-group"
              onChange={(e) => updateFieldValue("status", e.target.value)}>
              <FormControlLabel
                value="ACTIVE"
                control={<Radio />}
                label={t("addHoliday.status.ACTIVE.label")}
              />
              <FormControlLabel
                value="INACTIVE"
                control={<Radio />}
                label={t("addHoliday.status.INACTIVE.label")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl sx={{ width: "100%", padding: "0.5rem" }} size="small">
            <Autocomplete
              multiple
              value={excludedClasses || []}
              placeholder="addHoliday.placeholder.excludedClasses"
              onChange={(e, value) => {
                const classes = value?.length > 0 ? value.map((x) => x) : [];
                setExcludedClasses(classes);
              }}
              requestConfig={{
                url: "v1/classes",
                label: "className",
                value: "uuid",
                responseField: (res) => res.results.map(({ classDetails }) => classDetails)
              }}></Autocomplete>
          </FormControl>
        </Grid>
        {/* <Grid item>
          <FormControlLabel
            sx={{ paddingX: "0.5rem", marginRight: 0 }}
            control={<Checkbox />}
            label={t("addHoliday.placeholder.sendNotification")}
            onChange={(e) => updateFieldValue("notification", e.target.value)}
          />
        </Grid> */}
      </Grid>
    </Dialog>
  );
};

export default AddHolidayDialog;
