import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CategoryIcon from "@mui/icons-material/Category";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import ClassIcon from "@mui/icons-material/Class";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SchoolIcon from "@mui/icons-material/School";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import BlockIcon from "@mui/icons-material/Block";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

const MENU_ITEMS = [
  {
    title: "home",
    to: "/",
    icon: <HomeIcon />
  },
  {
    title: "academic",
    icon: <SchoolIcon />,
    submenu: [
      {
        title: "academic.class",
        to: "/academic/classes",
        icon: <ClassIcon />
      },
      {
        title: "academic.timetable",
        to: "/academic/timetable",
        icon: <CalendarMonthIcon />
      },
      {
        title: "academic.holidays",
        to: "/academic/holidays",
        icon: <CelebrationIcon />
      },
      {
        title: "academic.attendance",
        to: "/academic/attendance",
        icon: <ChecklistRtlIcon />
      },
      {
        title: "academic.attendanceHistories",
        to: "/academic/attendance-histories",
        icon: <HistoryEduIcon />
      },
      {
        title: "academic.students",
        to: "/academic/students",
        icon: <GroupsIcon />
      },
      {
        title: "academic.makeupClass",
        to: "/academic/makeup-class",
        icon: <AutoStoriesIcon />
      },
      {
        title: "academic.loaStudents",
        to: "/academic/loa-students",
        icon: <BlockIcon />
      },
      {
        title: "academic.disabledStudents",
        to: "/academic/disabled-students",
        icon: <DisabledByDefaultIcon />
      }
    ]
  },
  {
    title: "finance",
    icon: <MonetizationOnIcon />,
    submenu: [
      {
        title: "finance.newPaymentContract",
        to: "/finance/payment-contract/new",
        icon: <PostAddIcon />
      },
      {
        title: "finance.invoices",
        to: "/finance/invoices",
        icon: <ReceiptLongIcon />
      },
      {
        title: "finance.feeReport",
        to: "/finance/fee-report",
        icon: <AssignmentLateIcon />
      }
    ]
  },
  {
    title: "reports",
    disabled: true,
    icon: <AssessmentIcon />,
    submenu: [
      {
        title: "reports.classReports",
        to: "/reports/class-reports",
        icon: <SummarizeIcon />
      },
      {
        title: "reports.financialReports",
        to: "/reports/financial-reports",
        icon: <ReceiptIcon />
      }
    ]
  },
  {
    title: "settings",
    icon: <SettingsIcon />,
    submenu: [
      {
        title: "settings.products",
        to: "/settings/products",
        icon: <CategoryIcon />
      },
      {
        title: "settings.teachers",
        to: "/settings/teachers",
        icon: <AssignmentIndIcon />
      },
      {
        title: "settings.classrooms",
        to: "/settings/classrooms",
        icon: <MeetingRoomIcon />
      }
    ]
  }
];

export default MENU_ITEMS;
