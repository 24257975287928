import { Delete, Edit } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import { Box, ButtonGroup, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, VisuallyHiddenInput } from "../../../components/button";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Status from "../../../components/status";
import Table from "../../../components/table";
import RequestHeaders from "../../../constants/RequestHeaders";
import { deleteById, get, post, postWithHeader, put } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import ClassDetailsDialog from "./ClassDetailsDialog";

const CLASS_ID_PREFIX = "SW-Class-";

const Classes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deletionRow, setDeletionRow] = useState(null);
  const [openClassDialog, setOpenClassDialog] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 30,
    order: "asc",
    orderBy: "className"
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [pagination]);

  useEffect(() => {
    setPagination({
      pageNumber: 0,
      pageSize: 30,
      order: "asc",
      orderBy: "className"
    });
  }, [branchInfo.value]);

  const refreshData = () => {
    get("v1/classes", {
      branchUuid: branchInfo.value.uuid,
      ...pagination
    })
      .then((res) => {
        const { pageSize, pageNumber } = pagination;
        setTotal(res.total);
        setData(res.results.map((x, index) => ({ id: pageNumber * pageSize + index + 1, ...x })));
      })
      .catch(console.debug);
  };

  const columns = [
    { field: "id", label: "class.table.field.id.label", sortable: false },
    {
      field: "classDetails.className",
      label: "class.table.field.className.label",
      valueGetter: ({ classDetails }) => (
        <Link to={`details/${classDetails.uuid}`}>
          <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
            {classDetails.className}
          </Typography>
        </Link>
      )
    },
    {
      field: "totalStudents",
      label: "class.table.field.totalStudents.label",
      valueGetter: ({ totalStudents }) => totalStudents || 0
    },
    {
      field: "trialStudents",
      label: "class.table.field.trialStudents.label",
      valueGetter: ({ trialStudents }) => trialStudents || 0
    },
    {
      field: "learningStudents",
      label: "class.table.field.learningStudents.label",
      valueGetter: ({ learningStudents }) => learningStudents || 0
    },
    {
      field: "inDebtStudents",
      label: "class.table.field.debtor.label",
      valueGetter: ({ inDebtStudents }) => inDebtStudents || 0
    },
    {
      field: "classDetails.schedule",
      label: "class.table.field.teacherNameAndSchedule.label",
      component: ({ classDetails }) => {
        return classDetails.schedule.map(
          ({ teacherName, classroomName, dayOfWeek, timeSlot }, index) => (
            <Typography key={index} fontSize="14px">
              {teacherName} / {timeSlot} {t(`timetable.weekDay.${getWeekDay(dayOfWeek)}.label`)} (
              {classroomName})
            </Typography>
          )
        );
      }
    },
    {
      field: "classDetails.schedule",
      label: "class.table.field.classStatus.label",
      component: (rowData) => (
        <Status status={`class.status.${rowData.classDetails.classStatus}.label`} />
      )
    }
  ];
  const rowActions = [
    {
      icon: <Edit />,
      tooltip: t("class.table.action.edit.label"),
      action: (rowData) => {
        setOpenClassDialog(true);
        setSelectedRow(rowData);
      }
    },
    {
      icon: <Delete />,
      tooltip: t("class.table.action.delete.label"),
      action: (rowData) => {
        setDeletionRow(rowData);
      }
    }
  ];

  const getWeekDay = (dayOfWeek) => dayOfWeek.substring(0, 3).toLowerCase();

  const handleClose = () => {
    setOpenClassDialog(false);
    setSelectedRow(null);
    setDeletionRow(null);
  };

  const uploadTeacherList = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", "TEACHER");
      postWithHeader(
        "v1/admin/csv",
        {
          [RequestHeaders.CONTENT_TYPE]: "multipart/form-data"
        },
        formData
      )
        .then(() => {
          refreshData();
        })
        .catch(console.debug);
    }
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Stars World Online Management" />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid item xs={4}></Grid>
        <Grid
          item
          xs={4}
          borderRadius="3px"
          sx={{ display: "flex", alignItems: "center", backgroundColor: colors.grey[900] }}>
          {/* <InputBase disabled sx={{ ml: 1, flex: 1 }} placeholder={t("class.placeholder.search")} />
          <IconButton disabled type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton> */}
        </Grid>
        <Grid item xs={4}>
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button
              component="label"
              variant="contained"
              startIcon={<UploadIcon />}
              color="secondary">
              {t("class.button.uploadTeachers")}
              <VisuallyHiddenInput type="file" accept=".csv" onChange={uploadTeacherList} />
            </Button>
            <Button startIcon={<DownloadIcon />} variant="contained" color="neutral" disabled>
              {t("common.button.excel")}
            </Button>
            <Button
              variant="contained"
              color="success"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => setOpenClassDialog(true)}>
              {t("common.button.new")}
            </Button>
          </ButtonGroup>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table
          columnConfig={columns}
          rowActions={rowActions}
          data={data}
          totalResults={total}
          serverPaging={true}
          defaultPagination={pagination}
          paginationChanged={(paging) => setPagination(paging)}
        />
      </Box>
      <ClassDetailsDialog
        open={openClassDialog}
        editable={!!selectedRow}
        data={selectedRow?.classDetails}
        onClose={handleClose}
        onConfirm={(newClass, formData) => {
          if (newClass) {
            post("v1/classes", {
              ...formData,
              classStatus: formData.status,
              classType: "ONLINE",
              classId: CLASS_ID_PREFIX + new Date().getTime()
            })
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          } else {
            const { classType, classId } = selectedRow;
            put(`v1/classes/${selectedRow?.classDetails.uuid}`, {
              ...formData,
              classStatus: formData.status,
              classType,
              classId
            })
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          }
        }}
        buttons={DialogButtons.CANCEL_SAVE}
        title={t(
          `class.dialog.${openClassDialog && !selectedRow ? "newClass" : "updateClass"}.title`
        )}
      />
      <Dialog
        open={!!deletionRow}
        data={deletionRow}
        onClose={handleClose}
        onConfirm={() => {
          deleteById("v1/classes", deletionRow?.classDetails.uuid)
            .then(() => {
              refreshData();
              handleClose();
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_DELETE}
        headerBgColor="#d32f2f"
        headerTitleColor="white"
        title={t("class.dialog.deleteClass.title")}>
        <Typography variant="h5" color={colors.grey[100]} sx={{ m: "0 0 5px 0" }}>
          <div
            style={{ marginBottom: "1rem" }}
            dangerouslySetInnerHTML={{
              __html: t("class.dialog.deleteClass.confirmTitle", {
                className: deletionRow?.className
              })
            }}></div>
          <div>{t("class.dialog.deleteClass.description")}</div>
        </Typography>
      </Dialog>
    </Box>
  );
};

export default Classes;
