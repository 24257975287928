import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get } from "../../../services/HttpClient";
import { branchInfo, invoiceCriterias } from "../../../signals";
import { tokens } from "../../../theme";
import { Link } from "react-router-dom";

const STATISTICS = [
  {
    type: "ITEM",
    totalAmount: 0
  },
  {
    type: "NEW",
    totalAmount: 0
  },
  {
    type: "RENEW",
    totalAmount: 0
  },
  {
    type: "VIRTUAL_CONTRACT",
    totalAmount: 0
  }
];

const PIE_COLORS = [
  {
    type: "ITEM",
    color: "hsl(162, 70%, 50%)"
  },
  {
    type: "NEW",
    color: "hsl(344, 70%, 50%)"
  },
  {
    type: "RENEW",
    color: "hsl(229, 70%, 50%)"
  },
  {
    type: "VIRTUAL_CONTRACT",
    color: "hsl(291, 70%, 50%)"
  }
];

const getMonthOptions = () => {
  const monthOptions = [];
  for (let i = 0; i <= 11; i++) {
    const m = moment().month(i);
    const startDayOfMonth = m.clone().startOf("month");
    const endDayOfMonth = m.clone().endOf("month");
    monthOptions.push({
      month: m.month(),
      year: m.isoWeekYear(),
      from: startDayOfMonth,
      to: endDayOfMonth
    });
  }
  return monthOptions;
};

const DEFAULT_SEARCH_CRITERIA = {
  fromMonthYear: moment().month() + "|" + moment().isoWeekYear(),
  toMonthYear: moment().month() + "|" + moment().isoWeekYear(),
  from: moment().startOf("month"),
  to: moment().endOf("month")
};

const FinanceReportDetails = ({ defaultExpanded }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([
    {
      month: 1,
      statistics: STATISTICS
    }
  ]);
  const { t } = useTranslation();
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);

  useEffect(() => {
    refreshData();
  }, [branchInfo.value, searchCriteria]);

  const refreshData = () => {
    if (branchInfo.value.uuid) {
      get(`v1/admin/statistics/${branchInfo.value.uuid}/revenue`, { ...searchCriteria })
        .then((res) => {
          if (res) {
            setData(
              res.map(({ month, statistics }) => {
                const totalItemAmounts = statistics.reduce((itemsAmount, s) => {
                  itemsAmount += s.itemsAmount;
                  return itemsAmount;
                }, 0);
                return {
                  month,
                  statistics: STATISTICS.map((x) => {
                    const stat = statistics.find(({ type }) => type === x.type);
                    if (x.type === "ITEM") {
                      return {
                        type: "ITEM",
                        totalAmount: totalItemAmounts
                      };
                    }
                    if (stat) {
                      const { type, totalAmount, itemsAmount } = stat;
                      return {
                        type: type,
                        totalAmount: totalAmount - itemsAmount
                      };
                    } else {
                      return {
                        type: x.type,
                        totalAmount: 0
                      };
                    }
                  })
                };
              })
            );
          }
        })
        .catch(console.debug);
    }
  };

  const handleMonthChange = (monthYear, isFrom = true) => {
    const { from, to } = getMonthOptions().find(({ month, year }) => {
      return month + "|" + year === monthYear;
    });
    if (isFrom) {
      const isFuture = from.isAfter(searchCriteria.to);
      setSearchCriteria({
        ...searchCriteria,
        from,
        fromMonthYear: monthYear,
        to: isFuture ? from : searchCriteria.to,
        toMonthYear: isFuture ? monthYear : searchCriteria.toMonthYear
      });
    } else {
      setSearchCriteria({
        ...searchCriteria,
        to,
        toMonthYear: monthYear
      });
    }
  };

  const getAllAmount = (data) => {
    return data.statistics.reduce((amount, { totalAmount }) => {
      amount += totalAmount;
      return amount;
    }, 0);
  };

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: colors.greenAccent[600]
        }}>
        <Typography variant="h4" fontWeight="bolder">
          {t("financeReports.details.title")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          backgroundColor={colors.primary[400]}
          display="flex"
          mb="0.5rem"
          p="0.5rem"
          justifyContent="space-between">
          <Grid item xs={4} />
          <Grid
            item
            container
            xs={4}
            display="flex"
            mb="0.5rem"
            p="0.5rem"
            justifyContent="space-between">
            <Grid item xs={6}>
              <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
                <InputLabel id="class-status-label" color="neutral">
                  {t("financeReport.placeholder.fromMonthOptions")}
                </InputLabel>
                <Select
                  labelId="class-status-label"
                  id="class-status"
                  label={t("financeReport.placeholder.fromMonthOptions")}
                  value={searchCriteria.fromMonthYear}
                  onChange={(e) => handleMonthChange(e.target.value, true)}
                  MenuProps={{
                    style: { zIndex: 15002 }
                  }}
                  sx={{ width: "100%" }}>
                  {getMonthOptions().map(({ month, year }, index) => (
                    <MenuItem key={index} value={month + "|" + year}>
                      {t("financeReport.monthOptions.label", {
                        month: month + 1,
                        year
                      })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
                <InputLabel id="class-status-label" color="neutral">
                  {t("financeReport.placeholder.toMonthOptions")}
                </InputLabel>
                <Select
                  labelId="class-status-label"
                  id="class-status"
                  label={t("financeReport.placeholder.toMonthOptions")}
                  value={searchCriteria.toMonthYear}
                  onChange={(e) => handleMonthChange(e.target.value, false)}
                  MenuProps={{
                    style: { zIndex: 15002 }
                  }}
                  sx={{ width: "100%" }}>
                  {getMonthOptions().map(({ month, year }, index) => (
                    <MenuItem
                      key={index}
                      disabled={moment().month(month).isBefore(searchCriteria.from)}
                      value={month + "|" + year}>
                      {t("financeReport.monthOptions.label", {
                        month: month + 1,
                        year
                      })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={4} />
        </Box>
        <Box
          backgroundColor={colors.primary[400]}
          display="flex"
          mb="0.5rem"
          p="0.5rem"
          justifyContent="space-between">
          <TableContainer component={Paper}>
            <Table aria-label="spanning table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t("financeReport.table.field.id.label")}</TableCell>
                  <TableCell align="center">
                    {t("financeReport.table.field.contractType.label")}
                  </TableCell>
                  {data.map(({ month }, index) => (
                    <TableCell key={index} align="left" rowSpan={2}>
                      {t("financeReport.table.field.month.label", {
                        month: month + 1
                      })}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {STATISTICS.map(({ type }, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      <Link
                        to={"../../finance/invoices"}
                        onClick={() => {
                          invoiceCriterias.value = {
                            fromDate: searchCriteria.from,
                            toDate: searchCriteria.to,
                            contractType: type
                          };
                        }}>
                        <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
                          {t(`financeReport.field.${type}.label`)}
                        </Typography>
                      </Link>
                    </TableCell>
                    {data.map(({ statistics }, index) => (
                      <TableCell key={index} align="left">
                        {statistics
                          .find((s) => s.type === type)
                          .totalAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "VND",
                            currencyDisplay: "symbol"
                          })}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box backgroundColor={colors.primary[400]} height="50vh" width="80vw" mb="0.5rem">
          {data.length === 1 ? (
            <ResponsivePie
              data={data[0].statistics.map(({ type, totalAmount }) => ({
                id: t(`financeReport.field.${type}.label`),
                label: t(`financeReport.field.${type}.label`),
                value: ((totalAmount / getAllAmount(data[0])) * 100).toFixed(2),
                color: PIE_COLORS.find((x) => x.type === type).color
              }))}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100]
                    }
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100]
                    }
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1
                    },
                    text: {
                      fill: colors.grey[100]
                    }
                  }
                },
                legends: {
                  text: {
                    fill: colors.grey[100]
                  }
                }
              }}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]]
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor={colors.grey[100]}
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              enableArcLabels={false}
              arcLabelsRadiusOffset={0.4}
              arcLabelsSkipAngle={7}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]]
              }}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
                }
              ]}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: 56,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000"
                      }
                    }
                  ]
                }
              ]}
            />
          ) : (
            <ResponsiveBar
              data={data.map(({ month, statistics }) => ({
                month: t("financeReport.table.field.monthValue.label", {
                  month: month + 1
                }),
                [t("financeReport.field.ITEM.label")]: statistics.find(
                  ({ type }) => type === "ITEM"
                ).totalAmount,
                [`${t("financeReport.field.ITEM.label")}Color`]: "hsl(229, 70%, 50%)",
                [t("financeReport.field.NEW.label")]: statistics.find(({ type }) => type === "NEW")
                  .totalAmount,
                [`${t("financeReport.field.NEW.label")}Color`]: "hsl(296, 70%, 50%)",
                [t("financeReport.field.RENEW.label")]: statistics.find(
                  ({ type }) => type === "RENEW"
                ).totalAmount,
                [`${t("financeReport.field.RENEW.label")}Color`]: "hsl(97, 70%, 50%)",
                [t("financeReport.field.VIRTUAL_CONTRACT.label")]: statistics.find(
                  ({ type }) => type === "VIRTUAL_CONTRACT"
                ).totalAmount,
                [`${t("financeReport.field.VIRTUAL_CONTRACT.label")}Color`]: "hsl(340, 70%, 50%)"
              }))}
              theme={{
                // added
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100]
                    }
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100]
                    }
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1
                    },
                    text: {
                      fill: colors.grey[100]
                    }
                  }
                },
                legends: {
                  text: {
                    fill: colors.grey[100]
                  }
                }
              }}
              keys={STATISTICS.map(({ type }) => t(`financeReport.field.${type}.label`))}
              indexBy="month"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={{ scheme: "nivo" }}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "#38bcb2",
                  size: 4,
                  padding: 1,
                  stagger: true
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "#eed312",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
                }
              ]}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]]
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: t("timetable.month.label"),
                legendPosition: "middle",
                legendOffset: 32
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: t("financeReport.table.field.totalAmount.label"),
                legendPosition: "middle",
                legendOffset: -40
              }}
              enableLabel={false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]]
              }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              role="application"
              barAriaLabel={function (e) {
                return e.id + ": " + e.formattedValue + " in month: " + e.indexValue;
              }}
            />
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FinanceReportDetails;
