import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PreviewIcon from "@mui/icons-material/Preview";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../components/button";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Status from "../../../components/status";
import Table from "../../../components/table";
import { get, post } from "../../../services/HttpClient";
import { branchInfo, renewInvoice } from "../../../signals";
import { tokens } from "../../../theme";
import { getStudentDetailLabel } from "../../../utils/UserUtil";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";

const DEFAULT_COURSE_PRICE = 120000;

const getDateValue = (date) => (date ? moment(new Date(date)).format("DD/MM/YYYY") : "");

const getHistoryStatusColor = (status) => {
  switch (status) {
    case "ACTIVE":
      return "secondary";
    case "ABORTED":
      return "warning";
    case "CHECKED_IN":
      return "success";
    case "ABSENCE":
      return "error";
    case "MAKE_UP_COMPLETED":
      return "info";
    case "LEAVE_OF_ABSENCE":
    default:
      return "primary";
  }
};

const getWeekDay = (weekDay) => {
  switch (weekDay) {
    case 1:
      return "mon";
    case 2:
      return "tue";
    case 3:
      return "wed";
    case 4:
      return "thu";
    case 5:
      return "fri";
    case 6:
      return "sat";
    case 7:
    default:
      return "sun";
  }
};

const SessionsDebtDetails = ({ defaultExpanded }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [sessionDebts, setSessionDebts] = useState([]);
  const [debtStatus, setDebtStatus] = useState("");
  const [showAttendance, setShowAttendance] = useState(false);
  const [studentAttendanceData, setStudentAttendanceData] = useState([]);
  const [notes, setNotes] = useState([]);
  const [badDebtData, setBadDebtData] = useState(null);

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [debtStatus, branchInfo.value]);

  const refreshData = () => {
    get("v1/students/debt", {
      branchUuid: branchInfo.value.uuid,
      debtStatus
    })
      .then((res) => {
        setSessionDebts(res.map((x, index) => ({ id: index + 1, ...x })));
        setNotes(res.map(({ debtNote }) => debtNote || ""));
      })
      .catch(console.debug);
  };

  const sessionsDebtColumns = [
    { field: "id", label: "sessionsDebts.table.field.id.label", sortable: false },
    {
      field: "studentName",
      width: "200px",
      label: "sessionsDebts.table.field.studentName.label",
      valueGetter: ({ studentValue }) => (
        <Link to={`../../academic/students/details/${studentValue.uuid}`}>
          <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
            {getStudentDetailLabel(studentValue)}
          </Typography>
        </Link>
      )
    },
    {
      field: "className",
      label: "sessionsDebts.table.field.className.label",
      valueGetter: ({ classValue }) => classValue.className
    },
    {
      field: "debtStatus",
      label: "sessionsDebts.table.field.sessionDebtType.label",
      component: ({ debtStatus }) => (
        <Status
          status={t(`sessionDebts.debtStatus.${debtStatus}`)}
          color={getStatusColor(debtStatus)}
        />
      )
    },
    {
      field: "attendanceHistories",
      width: "150px",
      label: "sessionsDebts.table.field.attendanceHistories.label",
      component: ({ studentValue, classValue }) => (
        <Button
          variant="text"
          color="success"
          endIcon={<PreviewIcon />}
          sx={{
            width: "fit-content"
          }}
          onClick={() =>
            get(`v1/students/${studentValue.uuid}/histories`, {
              classUuid: classValue.uuid
            })
              .then((res) => {
                setStudentAttendanceData(res);
                setShowAttendance(true);
              })
              .catch(console.debug)
          }>
          {t("sessionsDebts.table.field.scheduleHistories")}
        </Button>
      )
    },
    {
      field: "debtStartingDate",
      width: "150px",
      label: "sessionsDebts.table.field.debtStartDate.label",
      valueGetter: ({ debtStartingDate }) => moment(debtStartingDate).format("DD/MM/YYYY")
    },
    {
      field: "debtQuantity",
      width: "100px",
      label: "sessionsDebts.table.field.inDebtQuantity.label"
    },
    {
      field: "debtNote",
      width: "300px",
      label: "sessionsDebts.table.field.note.label",
      sortable: false,
      component: ({ notes, id, noteChange, ...rowData }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            value={notes[id - 1]}
            onChange={(e) => {
              noteChange(e, id);
            }}
            multiline
            rows={3}
            variant="outlined"
            sx={{ width: "100%" }}
            size="small"
          />
          <IconButton
            variant="contained"
            color="success"
            onClick={() => {
              const { classValue, studentValue, debtNoteUuid } = rowData;
              post("v1/students/debt", {
                uuid: debtNoteUuid,
                branchUuid: branchInfo.value.uuid,
                studentUuid: studentValue.uuid,
                classUuid: classValue.uuid,
                status: "ACTIVE",
                debtType: "LEARNING_IN_DEBT",
                note: notes[id - 1]
              })
                .then(() => {
                  refreshData();
                })
                .catch(console.debug);
            }}>
            <SaveAsIcon />
          </IconButton>
        </Box>
      )
    }
  ];
  const rowActions = [
    {
      icon: <CurrencyExchangeIcon />,
      tooltip: t("sessionsDebts.table.action.pay.label"),
      action: (rowData) => {
        const { packageValue } = rowData;
        renewInvoice.value = {
          ...rowData,
          registeredSessions: packageValue?.quantity || 0,
          isSettlement: false
        };
        navigate("../payment-contract/new");
      }
    },
    {
      icon: <PriceCheckIcon />,
      tooltip: t("sessionsDebts.table.action.settlement.label"),
      action: (rowData) => {
        renewInvoice.value = {
          ...rowData,
          registeredSessions: rowData.debtQuantity,
          isSettlement: !!rowData.packageValue
        };
        navigate("../payment-contract/new");
      },
      showIf: ({ debtStatus }) => debtStatus === "STD_IN_DEBT"
    },
    {
      icon: <MoneyOffIcon />,
      tooltip: t("sessionsDebts.table.action.badDebt.label"),
      action: (rowData) => {
        setBadDebtData(rowData);
      },
      showIf: ({ debtStatus }) => debtStatus === "STD_IN_DEBT"
    }
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "STD_IN_DEBT":
        return "info";
      case "STD_TRIAL_IN_DEBT":
      default:
        return "secondary";
    }
  };

  const getAmountText = (amount, showCode = false) =>
    amount.toLocaleString("en-US", {
      style: "currency",
      currency: "VND",
      currencyDisplay: showCode ? "code" : "symbol"
    });

  return (
    <>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: colors.greenAccent[600]
          }}>
          <Typography variant="h4" fontWeight="bolder">
            {t("feeReport.sessionDebts.title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            backgroundColor={colors.primary[400]}
            mb="0.5rem"
            p="0.5rem"
            justifyContent="space-between">
            <Grid
              container
              mb="1rem"
              columnSpacing={1}
              sx={{ display: "flex", alignItems: "center" }}>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
                  <InputLabel id="contract-type-label" color="neutral">
                    {t("sessionDebts.placeholder.sessionDebtType")}
                  </InputLabel>
                  <Select
                    labelId="contract-type-label"
                    id="contract-type"
                    label={t("sessionDebts.placeholder.sessionDebtType")}
                    value={debtStatus || ""}
                    onChange={(e) => setDebtStatus(e.target.value)}
                    MenuProps={{
                      style: { zIndex: 15003 }
                    }}
                    sx={{ width: "100%" }}>
                    <MenuItem value="">{t("sessionDebts.debtStatus.ALL")}</MenuItem>
                    <MenuItem value="STD_IN_DEBT">
                      {t("sessionDebts.debtStatus.STD_IN_DEBT")}
                    </MenuItem>
                    <MenuItem value="STD_TRIAL_IN_DEBT">
                      {t("sessionDebts.debtStatus.STD_TRIAL_IN_DEBT")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h5">
                  {t("sessionDebts.totalDebtQuantity.label")}:&nbsp;
                  <b>
                    {sessionDebts.reduce((totalDebt, { debtQuantity }) => {
                      totalDebt += debtQuantity;
                      return totalDebt;
                    }, 0)}
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h5">
                  {t("sessionDebts.totalDebtAmount.label")}:&nbsp;
                  <b>
                    {getAmountText(
                      sessionDebts.reduce((totalDebAmount, { debtQuantity, packageValue }) => {
                        totalDebAmount +=
                          debtQuantity *
                          (packageValue ? packageValue.singlePrice : DEFAULT_COURSE_PRICE);
                        return totalDebAmount;
                      }, 0)
                    )}
                  </b>
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row-reverse" columnSpacing={1}></Grid>
          </Box>
          <Table
            columnConfig={sessionsDebtColumns}
            showHeaderToolbar={false}
            showFooterToolbar={false}
            rowActions={rowActions}
            data={sessionDebts.map((x) => ({
              ...x,
              notes,
              noteChange: (e, id) => {
                const noteList = [...notes];
                noteList.splice(id - 1, 1, e.target.value);
                setNotes(noteList);
              }
            }))}
          />
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={!!badDebtData}
        onClose={() => setBadDebtData(null)}
        onConfirm={() => {
          const { id, classValue, studentValue, debtNoteUuid, debtQuantity } = badDebtData;
          post("v1/students/debt", {
            uuid: debtNoteUuid,
            branchUuid: branchInfo.value.uuid,
            studentUuid: studentValue.uuid,
            classUuid: classValue.uuid,
            debtQuantity,
            status: "ACTIVE",
            debtType: "BAD_DEBT",
            note: notes[id - 1]
          })
            .then(() => {
              refreshData();
              setBadDebtData(null);
            })
            .catch(console.debug);
        }}
        buttons={DialogButtons.CANCEL_CONFIRM}
        headerBgColor="#d32f2f"
        headerTitleColor="white"
        title={t("sessionsDebts.dialog.badDebt.title")}>
        <Typography variant="h5" color={colors.grey[100]} sx={{ m: "0 0 5px 0" }}>
          <div>{t("sessionsDebts.dialog.badDebt.description")}</div>
        </Typography>
      </Dialog>
      <Dialog
        open={!!showAttendance}
        onClose={() => setShowAttendance(false)}
        onConfirm={() => setShowAttendance(false)}
        width="60%"
        buttons={DialogButtons.OK}
        title={t("sessionsDebts.attendanceDetails.label")}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {studentAttendanceData.map((history, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <CardContent
                  key={index}
                  sx={{ background: colors.grey[900], margin: "0.5rem", borderRadius: "5px" }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {t(`timetable.weekDay.${getWeekDay(history.weekDay)}.label`)}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {getDateValue(history.scheduleDate)}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {history.timeSlot}
                  </Typography>
                  <Status
                    status={t(`attendance.historyStatus.${history.historyStatus}`)}
                    color={getHistoryStatusColor(history.historyStatus)}
                  />
                </CardContent>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default SessionsDebtDetails;
