import { Edit } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/button";
import { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Status from "../../../components/status";
import Table from "../../../components/table";
import { get, post, put } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import TeacherDetailsDialog from "./TeacherDetailsDialog";

const Teachers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [openTeacherDialog, setOpenTeacherDialog] = useState(false);
  const [teacherDetails, setTeacherDetails] = useState(null);

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [branchInfo.value]);

  const refreshData = () => {
    get(`v1/teachers?branchUuid=${branchInfo.value.uuid}&orderBy=displayName&order=asc`)
      .then((res) => {
        const teachers = res.results.map((x, index) => ({
          ...x,
          id: index + 1,
          mobile: getMobileNumber(x.mobile)
        }));
        setTeachers(teachers);
      })
      .catch(console.debug);
  };

  const getMobileNumber = (number) =>
    number === "-" || number.startsWith("+") ? number : "0" + number;

  const courseColumns = [
    { field: "id", label: "teachers.table.field.id.label" },
    {
      field: "niceName",
      label: "teachers.table.field.teacherName.label"
    },
    {
      field: "mobile",
      label: "teachers.table.field.mobileNumber.label"
    },
    {
      field: "email",
      label: "teachers.table.field.email.label",
      sortable: false
    },
    {
      field: "birthday",
      label: "teachers.table.field.birthday.label",
      sortable: false,
      valueGetter: ({ birthday }) =>
        birthday ? moment(new Date(birthday)).format("DD/MM/YYYY") : ""
    },
    {
      field: "status",
      label: "teachers.table.field.status.label",
      align: "center",
      component: (rowData) => (
        <Status
          status={t(`teachers.status.${rowData.status}.label`)}
          color={getStatusColor(rowData.status)}
        />
      )
    }
  ];
  const rowActions = [
    {
      icon: <Edit />,
      tooltip: t("teachers.button.edit"),
      action: (rowData) => {
        setOpenTeacherDialog(true);
        setTeacherDetails(rowData);
      }
    }
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "success";
      default:
        return "warning";
    }
  };

  const handleClose = () => {
    setOpenTeacherDialog(false);
    setTeacherDetails(null);
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Stars World Online Management" />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Typography variant="h3" fontWeight="bold" marginY="1rem">
          {t("teachers.header.label")}
        </Typography>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          color="success"
          sx={{
            marginBottom: "0.5rem"
          }}
          onClick={() => {
            setOpenTeacherDialog(true);
          }}>
          {t("teachers.button.new")}
        </Button>
        <Table
          columnConfig={courseColumns}
          data={teachers}
          showHeaderToolbar={false}
          rowActions={rowActions}
        />
      </Box>
      <TeacherDetailsDialog
        open={openTeacherDialog}
        data={teacherDetails}
        onClose={handleClose}
        onConfirm={(res) => {
          if (!teacherDetails?.uuid) {
            post("v1/teachers", res)
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          } else {
            put(`v1/teachers/${teacherDetails.uuid}`, res)
              .then(() => {
                refreshData();
                handleClose();
              })
              .catch(console.debug);
          }
        }}
        buttons={DialogButtons.CANCEL_CONFIRM}
        title={t(`teachers.dialog.${teacherDetails?.uuid ? "update" : "new"}.title`, {
          teacherName: teacherDetails?.teacherName
        })}
      />
    </Box>
  );
};

export default Teachers;
