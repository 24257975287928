import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../components/table";
import { get, post } from "../../../services/HttpClient";
import { branchInfo, renewInvoice } from "../../../signals";
import { getStudentDetailLabel } from "../../../utils/UserUtil";
import { tokens } from "../../../theme";

const DEFAULT_COURSE_PRICE = 120000;

const BadDebtDetails = ({ defaultExpanded }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [badDebts, setBadDebts] = useState([]);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [branchInfo.value]);

  const refreshData = () => {
    get(`v1/students/badDebt?branchUuid=${branchInfo.value.uuid}`)
      .then((res) => {
        setBadDebts(res.map((x, index) => ({ id: index + 1, ...x })));
        setNotes(res.map(({ debtNote }) => debtNote || ""));
      })
      .catch(console.debug);
  };

  const badDebtColumns = [
    { field: "id", label: "badDebts.table.field.id.label", sortable: false },
    {
      field: "studentName",
      width: "200px",
      label: "badDebts.table.field.studentName.label",
      valueGetter: ({ studentValue }) => (
        <Link to={`../../academic/students/details/${studentValue.uuid}`}>
          <Typography sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
            {getStudentDetailLabel(studentValue)}
          </Typography>
        </Link>
      )
    },
    {
      field: "className",
      label: "badDebts.table.field.className.label",
      valueGetter: ({ classValue }) => classValue.className
    },
    {
      field: "debtDates",
      label: "badDebts.table.field.debtDates.label",
      valueGetter: ({ debtDates }) =>
        (debtDates || []).map((date) => moment(date).format("DD/MM/YYYY")).join(", ")
    },
    {
      field: "debtQuantity",
      width: "100px",
      label: "badDebts.table.field.debtQuantity.label"
    },
    {
      field: "debtNote",
      width: "300px",
      label: "badDebts.table.field.note.label",
      sortable: false,
      component: ({ notes, id, noteChange, ...rowData }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            value={notes[id - 1]}
            onChange={(e) => {
              noteChange(e, id);
            }}
            multiline
            rows={3}
            variant="outlined"
            sx={{ width: "100%" }}
            size="small"
          />
          <IconButton
            variant="contained"
            color="success"
            onClick={() => {
              const { classValue, studentValue, debtNoteUuid } = rowData;
              post("v1/students/debt", {
                uuid: debtNoteUuid,
                branchUuid: branchInfo.value.uuid,
                studentUuid: studentValue.uuid,
                classUuid: classValue.uuid,
                status: "ACTIVE",
                debtType: "BAD_DEBT",
                note: notes[id - 1]
              })
                .then(() => {
                  refreshData();
                })
                .catch(console.debug);
            }}>
            <SaveAsIcon />
          </IconButton>
        </Box>
      )
    }
  ];
  const rowActions = [
    {
      icon: <PriceCheckIcon />,
      tooltip: t("badDebts.table.action.pay.label"),
      action: (rowData) => {
        renewInvoice.value = {
          ...rowData,
          registeredSessions: rowData.debtQuantity,
          isSettlement: true
        };
        navigate("../payment-contract/new");
      }
    }
  ];

  const getAmountText = (amount, showCode = false) =>
    amount.toLocaleString("en-US", {
      style: "currency",
      currency: "VND",
      currencyDisplay: showCode ? "code" : "symbol"
    });

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: "#db0000"
        }}>
        <Typography variant="h4" fontWeight="bolder">
          {t("feeReport.badDebts.title")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          backgroundColor={colors.primary[400]}
          mb="0.5rem"
          p="0.5rem"
          justifyContent="space-between">
          <Grid
            container
            mb="1rem"
            columnSpacing={1}
            sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={4}>
              <Typography variant="h5">
                {t("badDebts.totalDebtQuantity.label")}:&nbsp;
                <b>
                  {badDebts.reduce((totalDebt, { debtQuantity }) => {
                    totalDebt += debtQuantity;
                    return totalDebt;
                  }, 0)}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">
                {t("badDebts.totalDebtAmount.label")}:&nbsp;
                <b>
                  {getAmountText(
                    badDebts.reduce((totalDebAmount, { debtQuantity, packageValue }) => {
                      totalDebAmount +=
                        debtQuantity *
                        (packageValue ? packageValue.singlePrice : DEFAULT_COURSE_PRICE);
                      return totalDebAmount;
                    }, 0)
                  )}
                </b>
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row-reverse" columnSpacing={1}></Grid>
        </Box>
        <Table
          columnConfig={badDebtColumns}
          rowActions={rowActions}
          showHeaderToolbar={false}
          data={badDebts.map((x) => ({
            ...x,
            notes,
            noteChange: (e, id) => {
              const noteList = [...notes];
              noteList.splice(id - 1, 1, e.target.value);
              setNotes(noteList);
            }
          }))}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default BadDebtDetails;
