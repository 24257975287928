const RequestHeaders = {
  CONTENT_TYPE: "Content-Type",
  ACESS_CONTROLL_ALLOW_HEADERS: "Access-Control-Allow-Headers",
  ACESS_CONTROLL_ALLOW_ORIGIN: "Access-Control-Allow-Origin",
  ACESS_CONTROLL_ALLOW_METHODS: "Access-Control-Allow-Methods",
  CUSTOM_ERROR_HANDLER: "X-Custom-Error-Handler",
  GLOBAL_LOADING: "X-Global-Loading",
  REQUEST_ID: "X-Request-Id"
};

export default RequestHeaders;
