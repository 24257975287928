import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../components/autocomplete";
import Dialog from "../../../components/dialog";
import { branchInfo } from "../../../signals";

const DEFAULT_STUDENT_ID = "SW-HV-" + new Date().getTime();
const DEFAULT_PARENT_ID = "SW-PH-" + new Date().getTime();

const NEW_PARENT_DATA = {
  uuid: "",
  nameAndPhone: "",
  username: DEFAULT_PARENT_ID,
  email: "",
  password: "",
  mobile: ""
};

const INITIAL_DATA = {
  username: DEFAULT_STUDENT_ID,
  parentUuid: "",
  password: "",
  firstName: "",
  lastName: "",
  displayName: "",
  entranceTestResult: "",
  referralSource: "PARENT",
  gender: "MALE",
  email: "",
  birthday: null,
  note: "",
  status: "STD_NEW"
};

const StudentDetailsDialog = ({ data, editable, onConfirm, ...props }) => {
  const { t } = useTranslation();

  const [studentDetails, setStudentDetails] = useState(INITIAL_DATA);
  const [parent, setParent] = useState(null);

  const updateFieldValue = (fieldName, fieldValue) => {
    setStudentDetails({ ...studentDetails, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if (editable && data) {
      setStudentDetails({
        ...data,
        birthday: data.birthday ? moment(data.birthday) : null,
        branchUuid: branchInfo.value.uuid,
        branchName: branchInfo.value.branchName
      });
      setParent(data.parent);
    } else {
      setStudentDetails(INITIAL_DATA);
      setParent(NEW_PARENT_DATA);
    }
  }, [data, editable]);

  const confirmSaveStudent = () => {
    const formData = {
      ...studentDetails,
      branchUuid: branchInfo.value.uuid,
      branchName: branchInfo.value.branchName,
      parent
    };
    onConfirm(!editable, formData);
  };

  const getParentNameAndPhone = (name, phone) => {
    if (name && phone !== "-") {
      return name + " - 0" + phone;
    }
    return name || phone || "";
  };

  return (
    <Dialog {...props} onConfirm={confirmSaveStudent} width="1000px">
      <Grid
        container
        p="1rem"
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "100%"
        }}
        rowSpacing={4}>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <Autocomplete
                onChange={(e, value) => {
                  setStudentDetails({
                    ...studentDetails,
                    branchUuid: branchInfo.value.uuid || "",
                    branchName: branchInfo.value.branchName || ""
                  });
                }}
                disabled
                value={{
                  uuid: branchInfo.value.uuid,
                  branchName: branchInfo.value.branchName
                }}
                placeholder="studentDetails.placeholder.branchName"
                requestConfig={{
                  url: "v1/admin/branches",
                  label: "branchName",
                  value: "uuid"
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="status-label" color="neutral">
                {t("studentDetails.placeholder.status")}
              </InputLabel>
              <Select
                labelId="status-label"
                id="status"
                label={t("studentDetails.placeholder.status")}
                MenuProps={{
                  style: { zIndex: 15003 }
                }}
                disabled={!editable}
                value={studentDetails.status}
                onChange={(e) => updateFieldValue("status", e.target.value)}
                sx={{ width: "100%" }}>
                <MenuItem value="STD_NEW">{t("studentList.status.STD_NEW.label")}</MenuItem>
                <MenuItem value="STD_ACTIVE">{t("studentList.status.STD_ACTIVE.label")}</MenuItem>
                <MenuItem value="STD_INACTIVE">
                  {t("studentList.status.STD_INACTIVE.label")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("studentDetails.placeholder.firstName")}
              value={studentDetails.firstName || ""}
              onChange={(e) => updateFieldValue("firstName", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("studentDetails.placeholder.lastName")}
              value={studentDetails.lastName || ""}
              onChange={(e) => updateFieldValue("lastName", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("studentDetails.placeholder.displayName")}
              value={studentDetails.displayName || ""}
              onChange={(e) => updateFieldValue("displayName", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="birthday"
                format="DD/MM/YYYY"
                label={t("studentDetails.placeholder.birthday")}
                sx={{ width: "100%", paddingX: "0.5rem" }}
                value={studentDetails.birthday}
                onChange={(e) => updateFieldValue("birthday", e)}
                slotProps={{
                  textField: { size: "small" },
                  popper: {
                    style: {
                      zIndex: 15003
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("studentDetails.placeholder.entranceTestResult")}
              value={studentDetails.entranceTestResult || ""}
              onChange={(e) => updateFieldValue("entranceTestResult", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
              <InputLabel id="status-label" color="neutral">
                {t("studentDetails.placeholder.referralSource")}
              </InputLabel>
              <Select
                labelId="status-label"
                id="status"
                label={t("studentDetails.placeholder.referralSource")}
                MenuProps={{
                  style: { zIndex: 15003 }
                }}
                value={studentDetails.referralSource}
                onChange={(e) => updateFieldValue("referralSource", e.target.value)}
                sx={{ width: "100%" }}>
                <MenuItem value="PARENT">
                  {t("studentDetails.referralSource.PARENT.label")}
                </MenuItem>
                <MenuItem value="TEACHER">
                  {t("studentDetails.referralSource.TEACHER.label")}
                </MenuItem>
                <MenuItem value="STUDENT">
                  {t("studentDetails.referralSource.STUDENT.label")}
                </MenuItem>
                <MenuItem value="EMPLOYEE">
                  {t("studentDetails.referralSource.EMPLOYEE.label")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container alignItems="center" display="none">
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("studentDetails.placeholder.username")}
              value={studentDetails.username || ""}
              onChange={(e) => updateFieldValue("username", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("studentDetails.placeholder.password")}
              value={studentDetails.password || ""}
              onChange={(e) => updateFieldValue("password", e.target.value)}
              fullWidth
              size="small"
              variant="outlined"
              sx={{ paddingX: "0.5rem" }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              paddingTop: "5px !important",
              width: "100%"
            }}>
            <FormControl sx={{ width: "100%", padding: "0.5rem" }} size="small">
              <Autocomplete
                placeholder="studentDetails.placeholder.selectParent"
                value={{
                  uuid: parent?.uuid || "",
                  firstName: parent?.firstName || "",
                  mobile: parent?.mobile || ""
                }}
                onChange={(e, value) => {
                  if (value) {
                    const { uuid, firstName, displayName, mobile, email, password, username } =
                      value;
                    setParent({
                      ...parent,
                      uuid,
                      firstName,
                      nameAndPhone: getParentNameAndPhone(firstName, mobile),
                      displayName,
                      mobile,
                      email,
                      password,
                      username
                    });
                  } else {
                    setParent(NEW_PARENT_DATA);
                  }
                }}
                requestConfig={{
                  url: `v1/user/parents?branchUuid=${branchInfo.value.uuid}`,
                  label: ({ firstName, mobile }) => getParentNameAndPhone(firstName, mobile),
                  value: "uuid"
                }}></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Grid item sx={{ paddingX: "0.5rem" }}>
              <Typography component="label">
                {editable
                  ? t("studentDetails.placeholder.updateParent")
                  : t("studentDetails.placeholder.newParent")}
              </Typography>
              {!parent ? (
                <IconButton color="success" onClick={() => setParent(NEW_PARENT_DATA)}>
                  <AddCircleOutlineIcon />
                </IconButton>
              ) : (
                <IconButton color="error" onClick={() => setParent(null)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        {parent && (
          <Grid
            item
            container
            sx={{ paddingTop: "0 !important", padding: "1rem" }}
            alignItems="center">
            <Grid item container alignItems="center">
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  disabled={!!parent?.uuid}
                  label={t("studentDetails.placeholder.parentName")}
                  value={parent?.firstName || ""}
                  onChange={(e) => setParent({ ...parent, firstName: e.target.value })}
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{ paddingX: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  disabled={!!parent?.uuid}
                  label={t("studentDetails.placeholder.parentMobile")}
                  value={parent?.mobile || ""}
                  onChange={(e) => setParent({ ...parent, mobile: e.target.value })}
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{ paddingX: "0.5rem" }}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  disabled={!!parent?.uuid}
                  label={t("studentDetails.placeholder.parentEmail")}
                  value={parent?.email || ""}
                  onChange={(e) => setParent({ ...parent, email: e.target.value })}
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{ paddingX: "0.5rem" }}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center" display="none">
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  disabled={!!parent?.uuid}
                  label={t("studentDetails.placeholder.parentUsername")}
                  value={parent?.username || ""}
                  onChange={(e) => setParent({ ...parent, username: e.target.value })}
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{ paddingX: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  disabled={!!parent?.uuid}
                  label={t("studentDetails.placeholder.parentPassword")}
                  value={parent?.password || ""}
                  onChange={(e) => setParent({ ...parent, password: e.target.value })}
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{ paddingX: "0.5rem" }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default StudentDetailsDialog;
