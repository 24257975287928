import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Collapse, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, Sidebar as ProSidebar, SubMenu, useProSidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import MENU_ITEMS from "../../constants/SidebarMenu";
import { userProfile } from "../../signals";
import { tokens } from "../../theme";

const Item = ({ title, to, icon, selected, disabled, setSelected, submenu }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return submenu ? (
    <SubMenu
      style={{
        backgroundColor: `${colors.primary[400]} !important`
      }}
      icon={icon}
      label={t(`sidebar.items.${title}.label`)}
      defaultOpen={selected?.includes(title)}>
      {submenu.map((props) => (
        <Item {...props} key={props.title} selected={selected} setSelected={setSelected} />
      ))}
    </SubMenu>
  ) : (
    <MenuItem
      active={selected === to}
      disabled={disabled}
      style={{
        color: colors.grey[100]
      }}
      onClick={() => {
        setSelected(to);
      }}
      icon={icon}
      component={<Link to={to} />}>
      <Typography>
        {t(`sidebar.items.${title}.label`)}
        <br />
        {disabled && <i>(coming soon...)</i>}
      </Typography>
    </MenuItem>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const { collapseSidebar, collapsed } = useProSidebar();
  const [selected, setSelected] = useState(location.pathname);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    userProfile.value.uuid && (
      <Box
        sx={{
          "& .ps-sidebar-root": {
            zIndex: 15001
          },
          "& .ps-sidebar-container": {
            background: `${colors.primary[400]} !important`,
            height: "max-content !important"
          },
          "& .ps-menuitem-root,.ps-menu-button": {
            backgroundColor: "transparent !important"
          },
          "& .ps-submenu-content": collapsed
            ? {
                backgroundColor: `${colors.primary[400]} !important`
              }
            : {
                backgroundColor: "transparent !important",
                paddingLeft: "2rem"
              },
          "& .ps-menu-button:hover": {
            color: "#868dfb !important"
          },
          "& .ps-active": {
            color: "#6870fa !important"
          }
        }}>
        <ProSidebar style={{ height: "100%" }}>
          <Menu>
            {/* LOGO AND MENU ICON */}
            <MenuItem
              icon={<MenuIcon />}
              onClick={() => {
                collapseSidebar();
              }}
              style={{ textAlign: "center", margin: "2rem 0 2rem 0", color: colors.grey[100] }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginLeft="0.75rem">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    alt="logo"
                    width="100px"
                    height="100px"
                    src={`${process.env.PUBLIC_URL}/assets/logo_stars_world.jpg`}
                    style={{
                      cursor: "pointer",
                      backgroundColor: theme.palette.mode === "dark" ? "transparent" : "#83d0d7",
                      borderRadius: "50%"
                    }}
                  />
                </Box>
              </Box>
            </MenuItem>
            <Collapse in={!collapsed}>
              <Box mb="25px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <PersonIcon
                    width="100px"
                    height="100px"
                    sx={{
                      width: "100px",
                      height: "100px",
                      cursor: "pointer",
                      borderRadius: "50%"
                    }}
                  />
                </Box>
                <Box textAlign="center">
                  <Typography
                    variant="h2"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}>
                    {userProfile.value.displayName || userProfile.value.email}
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]}>
                    {userProfile.value.roles}
                  </Typography>
                </Box>
              </Box>
            </Collapse>
            {MENU_ITEMS.map((props) => (
              <Item {...props} key={props.title} selected={selected} setSelected={setSelected} />
            ))}
          </Menu>
        </ProSidebar>
      </Box>
    )
  );
};

export default Sidebar;
