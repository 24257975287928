export const SCHEDULE_TIMES = [
  {
    weekDay: 1,
    weekDayLabel: "Thứ 2",
    timeSlots: [
      "17:00-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:15-20:45",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 2,
    weekDayLabel: "Thứ 3",
    timeSlots: [
      "17:00-18:00",
      "17:00-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:10-19:40",
      "18:30-20:00",
      "19:00-20:30",
      "19:15-20:15",
      "19:15-20:45",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 3,
    weekDayLabel: "Thứ 4",
    timeSlots: [
      "17:00-18:30",
      "17:30-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:15-20:45",
      "19:30-20:30",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 4,
    weekDayLabel: "Thứ 5",
    timeSlots: [
      "17:00-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:15-20:45",
      "19:30-20:30",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 5,
    weekDayLabel: "Thứ 6",
    timeSlots: [
      "17:00-18:00",
      "17:00-18:30",
      "17:15-18:15",
      "17:30-19:00",
      "18:00-19:30",
      "18:10-19:40",
      "18:30-20:00",
      "19:00-20:30",
      "19:15-20:15",
      "19:15-20:45",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 6,
    weekDayLabel: "Thứ 7",
    timeSlots: [
      "08:00-09:30",
      "08:30-10:00",
      "08:30-10:30",
      "09:00-10:30",
      "09:30-11:00",
      "10:00-11:30",
      "15:00-16:30",
      "16:00-17:00",
      "16:00-17:30",
      "16:30-18:00",
      "17:00-18:00",
      "17:00-18:30",
      "17:30-18:45",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:15-20:45",
      "19:30-21:00"
    ]
  },
  {
    weekDay: 7,
    weekDayLabel: "Chủ nhật",
    timeSlots: [
      "08:00-09:30",
      "08:30-10:00",
      "08:30-10:30",
      "09:00-10:30",
      "09:30-11:00",
      "10:00-11:30",
      "15:00-16:30",
      "16:00-17:30",
      "16:30-18:00",
      "17:00-18:30",
      "17:30-19:00",
      "18:00-19:30",
      "18:30-20:00",
      "19:00-20:30",
      "19:15-20:45",
      "19:30-20:30",
      "19:30-21:00"
    ]
  }
];
