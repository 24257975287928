import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import CookieConstants from "../../constants/Cookies";
import { post } from "../../services/HttpClient";
import { userProfile } from "../../signals";
import { tokens } from "../../theme";
import { removeCookie, setCookie } from "../../utils/CookieUtil";
import { fetchCurrentInfo } from "../../utils/UserUtil";

const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loginFailedMsg, setLoginFailedMsg] = useState(null);
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    setLoginFailedMsg(null);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    post("v1/auth/emailOrUsername", {
      emailOrUsername: data.get("emailOrUsername"),
      password: data.get("password")
    })
      .then((res) => {
        if (res.status === "FAILED") {
          removeCookie(CookieConstants.ACCESS_TOKEN);
          userProfile.value = {};
          setLoginFailedMsg(t("commonMsg.login.loginFailed"));
        }
        setCookie(CookieConstants.ACCESS_TOKEN, res.accessToken);
        setCookie(CookieConstants.REFRESH_TOKEN, res.refreshToken);
        return;
      })
      .then(() =>
        fetchCurrentInfo(
          () => navigate("/"),
          () => {
            userProfile.value = {};
            removeCookie(CookieConstants.ACCESS_TOKEN);
            setLoginFailedMsg(t("commonMsg.login.unknownError"));
          }
        )
      );
  };

  return (
    <Grid container component="main" sx={{ height: "98vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
          backgroundRepeat: "no-repeat",
          backgroundColor: colors.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Stars World Online
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="emailOrUsername"
              label={t("login.placehoklder.emailOrUsername")}
              name="emailOrUsername"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("login.placehoklder.password")}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {loginFailedMsg && (
              <Typography variant="h5" color="error">
                {loginFailedMsg}
              </Typography>
            )}
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Login
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
