import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme
} from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme";

export const DialogButtons = {
  CANCEL_CONFIRM: [
    {
      type: "discard",
      id: "cancel",
      label: "common.button.cancel",
      variant: "outlined"
    },
    {
      type: "confirm",
      id: "confirm",
      label: "common.button.confirm"
    }
  ],
  CANCEL_DELETE: [
    {
      type: "discard",
      id: "cancel",
      label: "common.button.cancel",
      variant: "outlined"
    },
    {
      type: "confirm",
      id: "delete",
      label: "common.button.delete"
    }
  ],
  CANCEL_SAVE: [
    {
      type: "discard",
      id: "cancel",
      label: "common.button.cancel",
      variant: "outlined"
    },
    {
      type: "confirm",
      id: "save",
      label: "common.button.save"
    }
  ],
  OK: [
    {
      type: "confirm",
      id: "ok",
      label: "common.button.ok"
    }
  ]
};

const getBtnColor = (btnId, defaultColor) => {
  if (defaultColor) {
    return defaultColor;
  }

  switch (btnId) {
    case "save":
    case "ok":
    case "confirm":
      return "success";
    case "delete":
      return "error";
    default:
      return "neutral";
  }
};

const Dialog = ({
  open,
  onClose,
  onConfirm,
  textContent,
  title,
  buttons,
  children,
  width,
  headerBgColor,
  headerTitleColor
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  return (
    <Fragment>
      <MUIDialog
        open={open}
        onClose={onClose}
        fullWidth
        sx={{
          zIndex: 15002,
          "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
            maxWidth: width
          }
        }}>
        <DialogTitle
          fontSize="18px"
          fontWeight="bolder"
          bgcolor={headerBgColor || colors.greenAccent[400]}
          sx={{ color: headerTitleColor || "black" }}>
          {title || ""}
        </DialogTitle>
        <DialogContent sx={{ padding: "1rem !important" }}>
          {textContent ? (
            <DialogContentText paddingX="0.5rem" component="span" fontWeight="bold">
              {textContent}
            </DialogContentText>
          ) : null}
          {children}
        </DialogContent>
        <DialogActions>
          {(buttons || []).map(({ id, type, label, startIcon, endIcon, variant, color }) => (
            <Button
              key={id}
              variant={variant || "contained"}
              color={getBtnColor(id, color)}
              startIcon={startIcon}
              onClick={type === "discard" ? onClose : onConfirm}
              endIcon={endIcon}>
              {t(label)}
            </Button>
          ))}
        </DialogActions>
      </MUIDialog>
    </Fragment>
  );
};

export default Dialog;
